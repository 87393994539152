<div class="container-fluid">
  <div class="row no-gutter">
      <div class="col-md-6 bg-light bg-image">
        <div class="login d-flex align-items-center py-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 col-xl-7 mx-auto">
                        <h3 class="display-4 text-lg-center login-text">{{'login.text.title'|translate}}</h3>
                        <div>
                          <h2 class="display-7 text-lg-center login-text">{{'login.text.subtitle'|translate}}</h2>		
                        </div>                        
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- The content half -->
  <div class="col-md-6 bg-light">
      <div class="login d-flex align-items-center py-5">
          <div class="container">
            <form class="form-horizontal" [formGroup]="loginForm">
              <div class="row">
                  <div class="col-lg-10 col-xl-7 mx-auto">
                      <h3 class="display-4 text-lg-center"><img [src]="logoLogin" alt="logo jnum"/></h3>
                          <div class="form-group mb-3">
                              <input id="inputEmail" type="text" formControlName="username" placeholder="{{'login.username'|translate}}" required autofocus="" class="form-control  border-0 shadow-sm px-4">
                              <app-show-errors [control]="username"></app-show-errors>
                          </div>
                          <div class="form-group mb-3">
                              <input id="inputPassword" type="password" formControlName="key" placeholder="{{'login.password'|translate}}" required class="form-control border-0 shadow-sm px-4 text-primary">
                              <app-show-errors [control]="key"></app-show-errors>
                          </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-lg-10 col-xl-7 mx-auto">
                  <button type="submit" (click)="onSubmit()" class="btn btn-primary btn-block text-uppercase form-control border-0 shadow-sm px-4">
                    <i class="fas fa-sign-in-alt" title="{{'global.acceder'|translate}}"
                    [attr.aria-label]="'global.acceder'|translate"></i>
                  </button>
                </div>
              </div>
            </form>
          </div><!-- End -->
      </div>
</div><!-- End -->
