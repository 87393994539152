import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

/**
 * Componente para mostrar mensajes
 */
@Component({
  standalone: true,
  imports: [TranslateModule],
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
  host: { "[class.ngb-toasts]": "true" },
})
export class ToastComponent {
  public data: any;

  constructor(public activeModal: NgbActiveModal) {}

  closeModal(): void {
    this.activeModal.close();
  }
}
