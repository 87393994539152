import { Injectable,OnInit } from '@angular/core';
import { GeneratedUserService } from 'src/app/generated/seguridad/user/service/generated-user.service';

/**
 * Servicio User
 * @extends {GeneratedUserService}
 * @implements {OnInit}
 */
@Injectable({
    providedIn: 'root',
})
export class UserService extends GeneratedUserService implements OnInit{
  override ngOnInit() {
    super.ngOnInit();
  }
}
