<div class="modal-header toastheader {{data.classname}}">
  <h2 class="modal-title {{data.textclassname}}">{{data.header}}</h2>
  <em class="fas fa-2x fa-times closetoast {{data.textclassname}}" (click)="activeModal.dismiss('Cross click')"></em>
</div>
<div class="modal-body">
  <div class="toasticon">
    <em class="{{data.icon}} fa-4x {{data.iconclass}}"></em>
  </div>
  <div [innerHTML]="data.msg" id="modal_content"></div>
</div>
<div class="modal-footer toastfooter">
  <button type="button" class="btn btn-primary btn-block {{data.classname}} {{data.textclassname}}"
    (click)="activeModal.close('Close click')">{{'global.cerrar'|translate}}</button>
</div>