export const environment = {
  production: true,
  serviceUrl: "https://fqa-kondo.oesia.com/backend/v1",
  firstPageIndex: 0,
  pageSize: 10,
  detallePageSize: 5,
  propSelectPageSize: 5,
  defaultLocale: "es_ES",
  timeToCache: 600000,
  maxNumTabs: 10,
  logos: {
    logo_login: "assets/images/jnum2.png",
    logo_login_lateral: "assets/images/jnum.png",
    logo_cabecera_menu: "assets/images/jnum.png",
  },
  editorConfig: {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [
      ["customClasses", "insertVideo", "toggleEditorMode"],
    ],
  },
};
