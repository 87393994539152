
<div class="modal-header">
  <h2 mat-dialog-title>{{'global.passwordchange'|translate}}</h2>
</div>
<div class="modal-body">
  <form [formGroup]="passwordForm" (keyup.enter)='onSubmit()'>
    <input type="text" class="hidden" />
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group required">
          <label for="password" class="control-label">{{'login.password'|translate}}</label>
          <input class="form-control" type="password" maxlength="12"
            minlength="5" id="password" formControlName="password"
            placeholder="{{'login.password'|translate}}" required>
          <app-show-errors [control]="password"></app-show-errors>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group required">
          <label for="newpassword" class="control-label">{{'login.newpassword'|translate}}</label>    
          <input class="form-control" type="password" maxlength="12"
            minlength="5" id="newpassword" formControlName="newpassword"
            placeholder="{{'login.newpassword'|translate}}" required>
            <small id="newspasswordHelp" class="form-text text-muted">{{'global.passwordmaxlength'|translate}}</small>
          <app-show-errors [control]="newpassword"></app-show-errors>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group required">
          <label for="confirmPassword" class="control-label">{{'login.passwordconfirm'|translate}}</label>
          <input class="form-control" type="password" maxlength="12"
            minlength="5" id="confirmPassword"
            formControlName="confirmPassword"
            placeholder="{{'login.passwordconfirm'|translate}}" required>
          <app-show-errors [control]="confirmPassword"></app-show-errors>
        </div>
      </div>
    </div>

  </form>
</div>
<div class="modal-footer">
  <nav class="col-md-12 mb-1 navbar navbar-expand-lg search-toolbar">
    <div class="collapse navbar-collapse" id="buttonBoxDialogPassword">
      <ul class="navbar-nav ml-auto nav-flex-icons">
        <li class="nav-item">
          <a class="nav-link waves-effect waves-light" (click)="closeModal()">
            <em class="fas fa-arrow-left" title="{{'global.volver'|translate}}" [attr.aria-label]="'global.volver'|translate"></em>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-effect waves-light" (click)="onSubmit()" appHasPermission permission="add_ent_generico_departamento">
            <em class="fas fa-save" title="{{'global.guardar'|translate}}" [attr.aria-label]="'global.guardar'|translate"></em>
          </a>
        </li>      
      </ul>
    </div>
  </nav>
</div>
