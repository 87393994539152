import { Component, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core";
import { Color, ScaleType } from "@swimlane/ngx-charts";
import { PortalService } from "../portal/service/portal.service";
import { Subscription, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ChartStatesDTO } from "./model/ChartStatesDTO";
import { ChartStatesIncidentDTO } from "./model/ChartStatesIncidentDTO";

interface Data {
  name: string;
  value: number; // Valor total
}

// Interfaz para las series individuales
interface barChartDataViviendaStacked {
  name: string;
  series: {
    name: string; // Nombre de la serie
    value: number; // Valor de la serie
  }[];
}

interface SeriesEntry {
  name: string; // Nombre de la serie
  value: number; // Valor de la serie
}

interface barChartDataHogarStacked {
  name: string;
  series: SeriesEntry[]; // Array de series
}

// interface barChartDataHogarSub {
//   name: string;
//   series: SeriesEntry[]; // Array de series
// }

// interface ChartComponent {
//   barChartDataViviendaStacked: any;
//   barChartDataHogarStacked: any;
//   barChartDataViviendaStackedA2: any;
//   barChartDataViviendaStackedA3: any;
//   barChartDataViviendaStackedA4: any;
//   barChartDataViviendaStackedA5: any;
//   barChartDataViviendaStackedA6: any;
//   barChartDataViviendaStackedB: any;
//   barChartDataViviendaStackedC: any;
//   barChartDataStates: any;
//   barChartDataHogarSub : any;
// }

// barChartDataViviendaStacked: Datos de gráfico de barras apiladas de vivienda
// barChartDataHogarStacked: Datos de gráfico de barras apiladas de hogar
// barChartDataViviendaStackedA2: Datos de gráfico de barras apiladas de vivienda A2
// barChartDataViviendaStackedA3: Datos de gráfico de barras apiladas de vivienda A3
// barChartDataViviendaStackedA4: Datos de gráfico de barras apiladas de vivienda A4
// barChartDataViviendaStackedA5: Datos de gráfico de barras apiladas de vivienda A5
// barChartDataViviendaStackedA6: Datos de gráfico de barras apiladas de vivienda A6
// barChartDataViviendaStackedB: Datos de gráfico de barras apiladas de vivienda B
// barChartDataViviendaStackedC: Datos de gráfico de barras apiladas de vivienda C
// barChartDataStates: Datos de estados del gráfico de barras

@Component({
  selector: "app-portal",
  templateUrl: "./portal.component.html",
  styleUrls: ["./portal.component.scss"],
})
export class PortalComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  // Datos para las gráficas de barras
  barChartDataVivienda: Data[] = [];
  barChartDataHogar: Data[] = [];

  // Opciones para la gráfica
  view: [number, number] = [400, 280];

  // Definir los dominios de color
  domainVivienda = [
    "#5AA454", // Verde
    "#A10A28", // Rojo oscuro
    "#C7B42C", // Amarillo
    "#004D40", // Gris claro
    "#1E88E5", // Azul claro
    "#D81B60", // Rosa fuerte
    "#FFC107", // Amarillo brillante
    "#8E24AA", // Púrpura
    "#FF5722", // Naranja fuerte
  ];

  domainHogar = [
    "#5AA454", // Verde
    "#A10A28", // Rojo oscuro
    "#C7B42C", // Amarillo
    "#1E88E5", // Azul claro
    "#D81B60", // Rosa fuerte
    "#FFC107", // Amarillo brillante
    "#FF5722", // Naranja fuerte
  ];

  domainStacked = [
    "#A10A28", // Rojo oscuro para Incidencias no resueltas
    "#1E88E5", // Azul claro para Incidencias resueltas automáticamente
    "#D81B60", // Rosa fuerte para Incidencias resueltas manualmente
    "#FFC107", // Amarillo brillante
  ];

  domainStackedA1 = [
    "#A10A28", // Rojo oscuro para Incidencias no resueltas
    "#1E88E5", // Azul claro para Incidencias resueltas automáticamente
    "#D81B60", // Rosa fuerte para Incidencias resueltas manualmente
    "#FFC107", // Amarillo brillante
    "#004D40", // Verde oscuro
    "#8E24AA", // Púrpura
    "#FF5722", // Naranja fuerte
  ];

  domainStackedA2 = [
    "#A10A28", // Rojo oscuro para Incidencias no resueltas
    "#1E88E5", // Azul claro para Incidencias resueltas automáticamente
    "#D81B60", // Rosa fuerte para Incidencias resueltas manualmente
    "#FFC107", // Amarillo brillante
    "#004D40", // Verde oscuro
    "#8E24AA", // Púrpura
    "#FF5722", // Naranja fuerte
  ];

  domainStackedA3 = [
    "#A10A28", // Rojo oscuro para Incidencias no resueltas
    "#1E88E5", // Azul claro para Incidencias resueltas automáticamente
    "#D81B60", // Rosa fuerte para Incidencias resueltas manualmente
    "#FFC107", // Amarillo brillante
    "#FF5722", // Naranja fuerte
    "#004D40", // Verde oscuro
  ];

  domainCaseStacked = [
    "#A10A28", // Rojo oscuro
    "#1E88E5", // Azul claro
    "#D81B60", // Rosa fuerte
    "#FFC107", // Amarillo brillante
    "#004D40", // Verde oscuro
    "#8E24AA", // Púrpura
    "#FF5722", // Naranja fuerte
    "#795548", // Marrón
    "#607D8B", // Azul grisáceo
  ];

  barChartDataViviendaStacked: barChartDataViviendaStacked[] = [];
  barChartDataHogarStacked: barChartDataHogarStacked[] = [];
  barChartDataCasosStacked: barChartDataViviendaStacked[] = [];
  barChartDataStates: any;
  barChartDataViviendaStackedA2: any;
  barChartDataViviendaStackedA3: any;
  barChartDataViviendaStackedA4: any;
  barChartDataViviendaStackedA5: any;
  barChartDataViviendaStackedA6: any;
  barChartDataViviendaStackedB: any;
  barChartDataViviendaStackedC: any;

  colorScheme: any;
  colorSchemeHogar: any;
  colorSchemeStacked: any;
  colorSchemeCaseStacked: any;
  colorSchemeStackedA1: any;
  colorSchemeStackedA2: any;
  colorSchemeStackedA3: any;

  groupedData: { [key: string]: number } = {};
  barChartDataHogarSub: any;

  constructor(
    private portalService: PortalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    localStorage.removeItem("cpro");
    localStorage.removeItem("cnum");
    this.searchChart();
  }

  ngOnDestroy(): void {
    if (
      this.subscriptions &&
      typeof this.subscriptions.unsubscribe === "function"
    ) {
      //console.log("Limpia subscriptions");
      this.subscriptions.unsubscribe();
    }
  }

  searchChart() {
    
    // Inicializar tempDataCases como un objeto vacío

    this.barChartDataHogarSub = [];
    this.barChartDataHogar = [];
    this.groupedData = {};
    this.barChartDataVivienda = [];
    this.barChartDataStates = [];
    this.barChartDataViviendaStacked = [];
    this.barChartDataCasosStacked = [];
    this.barChartDataViviendaStackedA2 = [];
    this.barChartDataViviendaStackedA3 = [];
    this.barChartDataViviendaStackedA4 = [];
    this.barChartDataViviendaStackedA5 = [];
    this.barChartDataViviendaStackedA6 = [];
    this.barChartDataViviendaStackedB = []; 
    this.barChartDataViviendaStackedC = [];
    
    const storedCpro = localStorage.getItem("cpro")
      ? JSON.parse(localStorage.getItem("cpro")!)
      : 0;
    const storedCnum = localStorage.getItem("cnum")
      ? JSON.parse(localStorage.getItem("cnum")!)
      : 0;
    this.loadData(storedCpro, storedCnum);
  }

  private adjustBarChartData(
    jsonData: Data[],
    targetProperty: "barChartDataVivienda" | "barChartDataHogar" 
  ): void {
    // Asignar los datos al targetProperty dinámicamente
    this[targetProperty] = jsonData;
  }

  private assignChartDataFromJson(
    jsonData: barChartDataViviendaStacked[]
  ): void {
    // Objeto para almacenar los datos clasificados
    const tempData: Record<string, barChartDataHogarStacked> = {
      "TS-A1": { name: "TS-A1", series: [] },
      "TS-A2": { name: "TS-A2", series: [] },
      "TS-A3": { name: "TS-A3", series: [] },
      "TS-A4": { name: "TS-A4", series: [] },
      "TS-A5": { name: "TS-A5", series: [] },
      "TS-A6": { name: "TS-A6", series: [] },
      "TS-B": { name: "TS-B", series: [] },
      "TS-C": { name: "TS-C", series: [] },
    };

    const hasData = Object.keys(this.groupedData).length > 0;
    if (!hasData) {
      this.groupedData = {};
    }

    jsonData.forEach((data) => {
      // Verificar que data tenga la estructura correcta
      if (data.series && Array.isArray(data.series)) {
        data.series.forEach((seriesData) => {
          // Crear la entrada de la serie para el Caso actual
          const seriesEntry = {
            name: seriesData.name, // Asignar el nombre de la serie
            value: seriesData.value, // Asignar el valor de la serie directamente
          };
          if (data.name.includes("Caso")) {
            if (!this.groupedData[seriesData.name]) {
              this.groupedData[seriesData.name] = 0;
            }
            this.groupedData[seriesData.name] = seriesData.value;
          } else if (tempData[data.name]) {
            tempData[data.name].series.push(seriesEntry);
          } else {
            console.warn(`Caso ${data.name} no clasificado.`);
          }
        });
      } else {
        console.warn(`Entrada ${data.name} no tiene la estructura esperada.`);
      }
    });

    const resultStates = Object.keys(this.groupedData).map((name) => ({
      name: name,
      value: this.groupedData[name],
    }));

    // Asignar estados
    this.barChartDataStates = resultStates;

    // Definir una función para crear esquemas de color
    const crearColorScheme = (domain: string[]): Color => ({
      name: "cool",
      selectable: true,
      group: ScaleType.Ordinal,
      domain: domain,
    });

    // Crear los esquemas de color
    this.colorScheme = crearColorScheme(this.domainVivienda);
    this.colorSchemeHogar = crearColorScheme(this.domainHogar);
    this.colorSchemeStacked = crearColorScheme(this.domainStacked);
    this.colorSchemeStackedA1 = crearColorScheme(this.domainStackedA1);
    this.colorSchemeStackedA2 = crearColorScheme(this.domainStackedA2);
    this.colorSchemeStackedA3 = crearColorScheme(this.domainStackedA3);
    this.colorSchemeCaseStacked = crearColorScheme(this.domainCaseStacked);
  }

  private adjustBarChartDataStacked(
    jsonData: barChartDataViviendaStacked[]
  ): void {
    // Solo proceder si jsonData no está vacío
    if (jsonData.length === 0) return;

    //this.barChartDataHogarStacked = jsonData;
    this.assignChartDataFromJson(jsonData);

    // Detectar cambios (si se está utilizando ChangeDetectorRef)
    this.cdr.detectChanges();
  }
  
  private BarChartData(
    jsonData: barChartDataViviendaStacked[],
    target:  'barChartDataHogarSub' | 'barChartDataViviendaStackedA4' | 'barChartDataViviendaStackedC' | 'barChartDataViviendaStackedA3' | 'barChartDataViviendaStackedA2' | 'barChartDataViviendaStacked' 
      | 'barChartDataViviendaStackedA5' | 'barChartDataViviendaStackedA6' | 'barChartDataViviendaStackedB'
  ): void {
    // Solo proceder si jsonData no está vacío
    if (jsonData.length === 0) return;

    this[target] = jsonData
  
    // Detectar cambios (si se está utilizando ChangeDetectorRef)
    this.cdr.detectChanges();
  }
  
  private mapToBarChartDataVivienda(
    jsonData: { nstad: string; tipo: string; total: number }[]
  ): barChartDataViviendaStacked[] {
    // Si no hay datos, devolver un array vacío
    if (jsonData.length === 0) return [];

    // Crear una fila para "Viviendas" y dividir los datos según el tipo
    const groupedData = jsonData.reduce((acc, item) => {
      // Si la propiedad 'tipo' no existe en el acumulador, la inicializamos
      if (!acc[item.tipo]) {
        acc[item.tipo] = {
          name: item.tipo, // Usar el tipo como nombre
          series: [],
        };
      }

      // Añadir el name (nstad) y su valor (total) a la serie del tipo correspondiente
      acc[item.tipo].series.push({
        name: item.nstad, // El nombre de la serie es el valor de 'nstad'
        value: item.total, // El valor es el 'total' proporcionado
      });

      return acc;
    }, {} as { [key: string]: { name: string; series: { name: string; value: number }[] } });

    // Convertir el objeto agrupado a un array
    return Object.values(groupedData);
  }

  async loadData(cpro: string, cnum: string): Promise<void> {
    const promises = [
      this.getWithTaksTsaC(cpro, cnum),
      this.getWithTaksTsa4(cpro, cnum),
      this.getWithTaksTsa3(cpro, cnum),
      this.getWithTaksTsa2(cpro, cnum),
      this.getWithTaksTsa1(cpro, cnum),
      this.getWithTaksCpro(cpro, cnum), //Estados
      this.gethogaresStates(cpro, cnum),
      this.getviviendasStates(cpro, cnum),
      this.getviviendasStatesUnresolved(cpro, cnum),
      // this.getWithTaksTsaB(cpro, cnum),
      // this.getWithTaksTsa5(cpro, cnum),
      // this.getWithTaksTsa6(cpro, cnum),
    ];

    const results = await Promise.allSettled(promises);

    results.forEach((result, index) => {
      if (result.status === "fulfilled") {
        console.log(`La promesa ${index} se resolvió`);
      } else if (result.status === "rejected") {
        console.error(`Error al cargar los datos para la promesa ${index}:`, result.reason);
      }
    });

    //console.log("All data load attempts completed");
  }

  getviviendasStatesUnresolved(cpro: any, cnum: any): void {
    const sub = this.portalService
      .findAllviviendasStatesUnresolved(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos getviviendasStatesUnresolved", data)),
        catchError((error) => {
          //console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesIncidentDTO[]) => {
        // Llamar a mapToBarChartDataVivienda para convertir los datos
        const jsonData: barChartDataViviendaStacked[] =
          this.mapToBarChartDataVivienda(data);

        // Asegurarse de que jsonData no esté vacío
        if (jsonData.length > 0) {
            this.adjustBarChartDataStacked(jsonData,);
            this.BarChartData(jsonData,"barChartDataHogarSub");
        }
      });

    this.subscriptions.add(sub);
  }

  getviviendasStates(cpro: any, cnum: any): void {
    const sub = this.portalService
      .findAllviviendasStates(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos viviendas", data)),
        catchError((error) => {
          console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesDTO[]) => {
        const jsonData: Data[] = this.mapToRecordStates(data);
        this.adjustBarChartData(jsonData, "barChartDataVivienda");
      });

    this.subscriptions.add(sub);
  }

  gethogaresStates(cpro: any, cnum: any): void {
    const sub = this.portalService
      .findAllhogaresStates(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos hogares", data)),
        catchError((error) => {
          console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesDTO[]) => {
        const jsonData: Data[] = this.mapToRecordStates(data);
        this.adjustBarChartData(jsonData, "barChartDataHogar");
      });

    this.subscriptions.add(sub);
  }


  getWithTaksTsa1(cpro: any, cnum: any): void {
    const sub = this.portalService.findAllTaksTas1(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos getWithTaksTsa1 ", data)),
        catchError((error) => {
          //console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesIncidentDTO[]) => {
        // Llamar a mapToBarChartDataVivienda para convertir los datos
        const jsonData: barChartDataViviendaStacked[] =
          this.mapToBarChartDataVivienda(data);

        // Asegurarse de que jsonData no esté vacío
        if (jsonData.length > 0) {
           this.BarChartData(jsonData,"barChartDataViviendaStacked");
        }
      });

    this.subscriptions.add(sub);
  }


  getWithTaksTsa2(cpro: any, cnum: any): void {
    const sub = this.portalService.findAllTaksTas2(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos getWithTaksTsa2 ", data)),
        catchError((error) => {
          //console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesIncidentDTO[]) => {
        // Llamar a mapToBarChartDataVivienda para convertir los datos
        const jsonData: barChartDataViviendaStacked[] =
          this.mapToBarChartDataVivienda(data);

        // Asegurarse de que jsonData no esté vacío
        if (jsonData.length > 0) {
           this.BarChartData(jsonData,'barChartDataViviendaStackedA2');
        }
      });

    this.subscriptions.add(sub);
  }

  getWithTaksTsa3(cpro: any, cnum: any): void {
    const sub = this.portalService.findAllTaksTas3(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos getWithTaksTsa3 ", data)),
        catchError((error) => {
          //console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesIncidentDTO[]) => {
        // Llamar a mapToBarChartDataVivienda para convertir los datos
        const jsonData: barChartDataViviendaStacked[] =
          this.mapToBarChartDataVivienda(data);

        // Asegurarse de que jsonData no esté vacío
        if (jsonData.length > 0) {
          this.BarChartData(jsonData,'barChartDataViviendaStackedA3');
        }
      });

    this.subscriptions.add(sub);
  }

  getWithTaksTsa4(cpro: any, cnum: any): void {
    const sub = this.portalService.findAllTaksTas4(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos getWithTaksTsa4 ", data)),
        catchError((error) => {
          //console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesIncidentDTO[]) => {
        // Llamar a mapToBarChartDataVivienda para convertir los datos
        const jsonData: barChartDataViviendaStacked[] =
          this.mapToBarChartDataVivienda(data);

        // Asegurarse de que jsonData no esté vacío
        if (jsonData.length > 0) {
          this.BarChartData(jsonData,'barChartDataViviendaStackedA4');
        }
      });

    this.subscriptions.add(sub);
  }

  getWithTaksTsa5(cpro: any, cnum: any): void {
    const sub = this.portalService.findAllTaksTas5(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos getWithTaksTsa5", data)),
        catchError((error) => {
          //console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesIncidentDTO[]) => {
        // Llamar a mapToBarChartDataVivienda para convertir los datos
        const jsonData: barChartDataViviendaStacked[] =
          this.mapToBarChartDataVivienda(data);

        // Asegurarse de que jsonData no esté vacío
        if (jsonData.length > 0) {
          this.BarChartData(jsonData,'barChartDataViviendaStackedA5');
        }
      });

    this.subscriptions.add(sub);
  }

  getWithTaksTsa6(cpro: any, cnum: any): void {
    const sub = this.portalService.findAllTaksTas6(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos getWithTaksTsa6", data)),
        catchError((error) => {
          //console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesIncidentDTO[]) => {
        // Llamar a mapToBarChartDataVivienda para convertir los datos
        const jsonData: barChartDataViviendaStacked[] =
          this.mapToBarChartDataVivienda(data);

        // Asegurarse de que jsonData no esté vacío
        if (jsonData.length > 0) {
          this.BarChartData(jsonData,'barChartDataViviendaStackedA6');
        }
      });

    this.subscriptions.add(sub);
  }

  getWithTaksTsaC(cpro: any, cnum: any): void {
    const sub = this.portalService.findAllTaksTasC(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos getWithTaksTsaC ", data)),
        catchError((error) => {
          //console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesIncidentDTO[]) => {
        // Llamar a mapToBarChartDataVivienda para convertir los datos
        const jsonData: barChartDataViviendaStacked[] =
          this.mapToBarChartDataVivienda(data);

        // Asegurarse de que jsonData no esté vacío
        if (jsonData.length > 0) {
          this.BarChartData(jsonData,'barChartDataViviendaStackedC');
        }
      });

    this.subscriptions.add(sub);
  }

  getWithTaksTsaB(cpro: any, cnum: any): void {
    const sub = this.portalService.findAllTaksTasB(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos getWithTaksTsaB ", data)),
        catchError((error) => {
          //console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesIncidentDTO[]) => {
        // Llamar a mapToBarChartDataVivienda para convertir los datos
        const jsonData: barChartDataViviendaStacked[] =
          this.mapToBarChartDataVivienda(data);

        // Asegurarse de que jsonData no esté vacío
        if (jsonData.length > 0) {
          this.BarChartData(jsonData,'barChartDataViviendaStackedB');
        }
      });

    this.subscriptions.add(sub);
  }

  getWithTaksCpro(cpro: any, cnum: any): void {
    const sub = this.portalService.findAllTaksWithCpro(cpro, cnum)
      .pipe(
        tap((data) => console.log("Datos recibidos getWithTaksCpro ", data)),
        catchError((error) => {
          //console.error("Error al obtener los datos:", error);
          return of([]);
        })
      )
      .subscribe((data: ChartStatesIncidentDTO[]) => {
        // Llamar a mapToBarChartDataVivienda para convertir los datos
        const jsonData: barChartDataViviendaStacked[] =
          this.mapToBarChartDataVivienda(data);

        // Asegurarse de que jsonData no esté vacío
        if (jsonData.length > 0) {
           this.adjustBarChartDataStacked(jsonData);
        }
      });

    this.subscriptions.add(sub);
  }

  private mapToRecordStates(data: ChartStatesDTO[]): Data[] {
    return data.map((item) => ({
      name: item.nstad,
      value: item.total,
    }));
  }
}
