import { Injectable, OnInit } from '@angular/core';
import { BaseService } from 'src/app/jnum-core/services/base.service';
import { TypeMessage } from 'src/app/jnum-core/enum/type-message.enum';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { Subject } from 'rxjs/internal/Subject';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { environment } from 'src/environments/environment'
import { PageEvent } from 'src/app/jnum-core/model/page-model';
import { PaginationPage } from 'src/app/jnum-core/util/pagination';
import { GenericopBean } from 'src/app/jnum-core/model/genericop-bean';
import { User } from 'src/app/customized/seguridad/user/model/user';
import { Rol } from 'src/app/customized/seguridad/rol/model/rol';
import { RolFilter } from 'src/app/customized/seguridad/rol/filter/rol-filter';

/**
 * Servicio generado User
 * @implements {OnInit}
 */
@Injectable({
    providedIn: 'root',
})
export class GeneratedUserService extends BaseService<User> implements OnInit{

 
  private _userCached!: Observable<PaginationPage<User>> | null;      
  private _reloadUserSubject: Subject<boolean>= new Subject();  
     
    
  /**
   * @constructor
   */
  constructor() {
    super();
    this.memcollection = "musers";
    this.url = environment.serviceUrl+'/seguridad/user';
  }
  
  /**
   * @returns void
   */
  ngOnInit(): void {}    
    
    
  /**
     * Get reload User Subject
     * 
   * @returns Observable
   */
  public getReloadUserSubject(): Observable<boolean> {
    return this._reloadUserSubject.asObservable();
  }

  /**
     * Set reload User Subject
     * 
   * @param  {boolean} reload
   */
  public setReloadUserSubject(reload:boolean) {
    this._reloadUserSubject.next(reload);
  }
    
  /**
   * Clear the cache
   */    
  override clearCache(){
    this.userCached=null;
  }

  /**
   * Get all entities.
   * @returns Observable
   */    
  override getAllEntities(): Observable<any>{
      if (this.memory) {
          return this.http.get(this.MSERVER_URL + this.memcollection);
      } else {
          if(!this.userCached || this.isExpiredCache(this.initialCachedTime)){
              this.initialCachedTime=new Date().getTime();
              this.userCached = super.getAllEntities();
          }        
          return this.userCached??of(null);
      }
  }    
    
                
  changePassword(newkey:string){
    const changePasswordurl= environment.serviceUrl+"/jwt/changepassword";        
    return this.http.post( changePasswordurl,newkey, { headers: this.getHttpHeaders() } );
  }              
    
  getRolesByUser( iduser: string ,event?:PageEvent): Observable<any> {
    return this.http.get<any>( this.buildUrl( this.url + "/roles/" + iduser,event ), { headers: this.getHttpHeaders() } );
  }
  
  getRolesAvailablesByUser( iduser: string , rolFilter: RolFilter,event?:PageEvent): Observable<any> {
    return this.http.post<any>( this.buildUrl( this.url + "/rolesav/" + iduser,event ),rolFilter, { headers: this.getHttpHeaders() } );
  }        
  
  addRoles( iduser: string, roles: Rol[] ): Observable<any> {
    return this.http.put( this.url + "/roles/" + iduser, roles, { headers: this.getHttpHeaders() } )
  }
  
  removeRoles( iduser: string, roles: Rol[] ): Observable<any> {
    return this.http.post( this.url + "/roles/" + iduser,  roles, { headers: this.getHttpHeaders() } );
  }
  
  generarExcelRolesByUser( iduser: string,event?:PageEvent): Observable<any> {
    return this.http.get<any>( this.buildUrl( this.url + "/excel/roles/" + iduser,event ), { headers: this.getHttpHeaders() } );
  }     
  



    /**
     * Getter userCached
     * @return {Observable<PaginationPage<User>>}
     */
    public get userCached(): Observable<PaginationPage<User>> | null {
        return this._userCached;
    }

    /**
     * Setter userCached
     * @param {Observable<PaginationPage<User>>} value
     */
    public set userCached(value: Observable<PaginationPage<User>> | null) {
        this._userCached = value;
    }
}