import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Token } from "src/app/jnum-core/model/token";
import { ChartStatesDTO } from "../model/ChartStatesDTO";
import { Observable, catchError, tap, of } from "rxjs";
import { ChartStatesIncidentDTO } from "../model/ChartStatesIncidentDTO";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class PortalService {

  // private apiUrl = environment.serviceUrl + "/ine/vivienda"  ;
  private apiUrl = environment.serviceUrl + "/utilidades/panel"  ;
  
  private _token!: Token;
  constructor(private http: HttpClient) {}

  /** Getter httpHeaders.
   *
   * @returns HttpHeaders
   */
  getHttpHeaders(): HttpHeaders {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser") ?? "");
    this.token = currentUser && currentUser.token;
    if (this.token) {
      return new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + this.token.jwtToken,
      });
    }
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });
  }

  /**
   * Getter token
   * @return {Token}
   */
  public get token(): Token {
    return this._token;
  }

  /**
   * Setter token
   * @param {Token} value
   */
  public set token(value: Token) {
    this._token = value;
  }

  getApiUrlTaksTsa1(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/takstas1/${cnum}`; 
  }

  getApiUrlTaksTsa2(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/takstas2/${cnum}`; 
  }

  getApiUrlTaksTsa3(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/takstas3/${cnum}`; 
  }

  getApiUrlTaksTsa5(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/takstas5/${cnum}`; 
  }

  getApiUrlTaksTsa6(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/takstas6/${cnum}`; 
  }

  getApiUrlTaksTsaB(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/takstasB/${cnum}`; 
  }
  getApiUrlTaksTsa4(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/takstas4/${cnum}`; 
  }

  getApiUrlTaksTsaC(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/takstasC/${cnum}`; 
  }

  getApiUrlTaksWithCpro(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/taks/${cnum}`; 
  }

  getApiUrlWithCpro(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/states/${cnum}`; 
  }

  getApiUrlWithCproUnresolved(cpro: number, cnum: number): string {
    return `${this.apiUrl}/${cpro}/states/${cnum}/unresolved`; 
  }


  findAllTaksTas1(cpro: number, cnum: number): Observable<ChartStatesIncidentDTO[]> {
    const urlWithCproTaksTas1 = this.getApiUrlTaksTsa1(cpro,cnum); // Call the method to append cpro

    return this.http
      .get<ChartStatesIncidentDTO[]>(urlWithCproTaksTas1, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesIncidentDTO[]>("findAllTaksTas1", [])
        )
      );
  }


  findAllTaksTas2(cpro: number, cnum: number): Observable<ChartStatesIncidentDTO[]> {
    const urlWithCproTaksTas2 = this.getApiUrlTaksTsa2(cpro,cnum); // Call the method to append cpro

    return this.http
      .get<ChartStatesIncidentDTO[]>(urlWithCproTaksTas2, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesIncidentDTO[]>("findAllTaksTas2", [])
        )
      );
  }

  findAllTaksTas4(cpro: number, cnum: number): Observable<ChartStatesIncidentDTO[]> {
    const urlWithCproTaksTas4 = this.getApiUrlTaksTsa4(cpro,cnum); // Call the method to append cpro

    return this.http
      .get<ChartStatesIncidentDTO[]>(urlWithCproTaksTas4, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesIncidentDTO[]>("findAllTaksTas4", [])
        )
      );
  }


  findAllTaksTas5(cpro: number, cnum: number): Observable<ChartStatesIncidentDTO[]> {
    const urlWithCproTaksTas5 = this.getApiUrlTaksTsa5(cpro,cnum); // Call the method to append cpro

    return this.http
      .get<ChartStatesIncidentDTO[]>(urlWithCproTaksTas5, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesIncidentDTO[]>("findAllTaksTas5", [])
        )
      );
  }


  findAllTaksTasB(cpro: number, cnum: number): Observable<ChartStatesIncidentDTO[]> {
    const urlWithCproTaksTasB = this.getApiUrlTaksTsaB(cpro,cnum); // Call the method to append cpro

    return this.http
      .get<ChartStatesIncidentDTO[]>(urlWithCproTaksTasB, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesIncidentDTO[]>("findAllTaksTas5", [])
        )
      );
  }


  findAllTaksTas6(cpro: number, cnum: number): Observable<ChartStatesIncidentDTO[]> {
    const urlWithCproTaksTas6 = this.getApiUrlTaksTsa6(cpro,cnum); // Call the method to append cpro

    return this.http
      .get<ChartStatesIncidentDTO[]>(urlWithCproTaksTas6, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesIncidentDTO[]>("findAllTaksTas6", [])
        )
      );
  }


  findAllTaksTasC(cpro: number, cnum: number): Observable<ChartStatesIncidentDTO[]> {
    const urlWithCproTaksTasC = this.getApiUrlTaksTsaC(cpro,cnum); // Call the method to append cpro

    return this.http
      .get<ChartStatesIncidentDTO[]>(urlWithCproTaksTasC, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesIncidentDTO[]>("findAllTaksTasC", [])
        )
      );
  }

  findAllTaksTas3(cpro: number, cnum: number): Observable<ChartStatesIncidentDTO[]> {
    const urlWithCproTaksTas3 = this.getApiUrlTaksTsa3(cpro,cnum); // Call the method to append cpro

    return this.http
      .get<ChartStatesIncidentDTO[]>(urlWithCproTaksTas3, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesIncidentDTO[]>("findAllTaksTas3", [])
        )
      );
  }
  
  findAllTaksWithCpro(cpro: number, cnum: number): Observable<ChartStatesIncidentDTO[]> {
    const urlWithCproTaks = this.getApiUrlTaksWithCpro(cpro,cnum); // Call the method to append cpro

    return this.http
      .get<ChartStatesIncidentDTO[]>(urlWithCproTaks, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesIncidentDTO[]>("findAllTaksWithCpro", [])
        )
      );
  }
  

  findAllviviendasStates(cpro: number, cnum: number): Observable<ChartStatesDTO[]> {
    const urlWithCpro = this.getApiUrlWithCpro(cpro,cnum); 

    return this.http
      .get<ChartStatesDTO[]>(urlWithCpro, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesDTO[]>("findAllviviendasStates", [])
        )
      );
  }

  findAllviviendasStatesUnresolved(cpro: number, cnum: number): Observable<ChartStatesIncidentDTO[]> {
    const urlWithCpro = this.getApiUrlWithCproUnresolved(cpro,cnum); // Call the method to append cpro

    return this.http
      .get<ChartStatesIncidentDTO[]>(urlWithCpro, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesIncidentDTO[]>("findAllviviendasStatesUnresolved", [])
        )
      );
  }
  

  getApiUrlWithCproHogares(cpro: number,cnum: number): string {
    return `${this.apiUrl}/hogares/${cpro}/states/${cnum}`; 
  }

  findAllhogaresStates(cpro: number,cnum: number): Observable<ChartStatesDTO[]> {
    const urlWithCpro = this.getApiUrlWithCproHogares(cpro,cnum); 

    return this.http
      .get<ChartStatesDTO[]>(urlWithCpro, {
        headers: this.getHttpHeaders(),
      })
      .pipe(
        tap((data) => data),
        catchError(
          this.handleError<ChartStatesDTO[]>("findAllviviendasStates", [])
        )
      );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
