<ul ngbNav #nav="ngbNav" class="nav-tabs" *ngIf="tabs.length>0" [(activeId)]="activeTabOutlet"
    (navChange)="onTabChange($event)">
    <li [ngbNavItem]="tab.outlet" *ngFor="let tab of tabs ; let index = index">
        <a ngbNavLink>{{tab.displayName}} <span (click)="closeTab(index, $event)"
                class="fas fa-times closemaintab"></span></a>
    </li>
</ul>
<div class="tab-content" *ngIf="tabs.length>0">
<router-outlet></router-outlet>
</div>
