import { Injectable } from "@angular/core";
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { filter } from "rxjs/operators";
import { JnumStateStrategy } from "src/app/jnum-core/routing/jnumstatestrategy.component";

/**
 * Clase para almacenar la historia de un outlet
 */
export class OutletHistory {
  outletname: string = "";
  outlethistory = [];
}

/**
 * Servicio de navegación
 */
@Injectable()
export class NavigationService {
  private outletname = "primary";
  private _outlethistory: OutletHistory[] = [];
  private _history: string[] = [];
  private _jnumstatestrategy!: JnumStateStrategy;

  /**
   * @param  {Router} router
   */
  constructor(protected router: Router, private spinner: NgxSpinnerService) {
    this.jnumstatestrategy = router.routeReuseStrategy as JnumStateStrategy;
    this.outlethistory.push(this.createNewOutletHistory("primary"));
  }

  /**
   * Método para indicar si se debe reutilizar una ruta
   *
   * @param  {boolean} reuseRoute
   */
  public shouldReuseRoute(reuseRoute: boolean) {
    this.jnumstatestrategy.reuseRoute(reuseRoute);
  }

  /**
   * Carga la ruta y la almacena el el historial de cada outlet
   *
   * @returns void
   */
  public loadRouting(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: any) => {
        this.history = this.getOutletHistory(this.outletname);
        if (localStorage.getItem("record")) {
          localStorage.removeItem("record");
          this.history.pop();
        }
        if (urlAfterRedirects != this.history[this.history.length - 1]) {
          if (urlAfterRedirects == "/" && this.outletname != "primary") {
            return;
          }
          this.history.push(urlAfterRedirects);
        }
      });
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.spinner.show();
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.spinner.hide();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  /**
   * Devuelve el historial de navegación en cada outlet
   *
   * @param  {string} outletname
   * @returns string
   */
  public getOutletHistory(outletname: string): string[] {
    let history = this.outlethistory.find((x) => x.outletname == outletname);
    if (!history) {
      history = this.createNewOutletHistory(outletname);
      this.outlethistory.push(history);
    }
    return history.outlethistory;
  }

  /**
   * Crea un nuevo array para almacenar el historico de navegación de un outlet
   *
   * @param  {any} outletname
   * @returns OutletHistory
   */
  protected createNewOutletHistory(outletname: any): OutletHistory {
    let newoulethistory = new OutletHistory();
    newoulethistory.outletname = outletname;
    return newoulethistory;
  }

  /**
   * Pone como historico activo el del outlet indicado
   *
   * @param  {string} outletname
   */
  public setHistory(outletname: string | undefined) {
    if (outletname) {
      this.outletname = outletname;
      let currentoutlethistory = this.outlethistory.find(
        (x) => x.outletname == outletname
      );
      if (!currentoutlethistory) {
        currentoutlethistory = new OutletHistory();
        currentoutlethistory.outletname = outletname;
        this.outlethistory.push(currentoutlethistory);
      }
    }
  }

  /**
   * Borra el historico de navegación de un determinado outlet
   *
   * @param  {string} outletname
   */
  public deleteHistory(outletname: string | undefined) {
    const outlethistoryindex = this.outlethistory.findIndex(
      (x) => x.outletname == outletname
    );
    this.outlethistory.splice(outlethistoryindex, 1);
    if (this.outlethistory.length == 1) {
      this.outletname = "primary";
    }
  }

  /**
   * Borra todos los historicos de navegación
   */
  public deleteAllHistory() {
    this.outlethistory.splice(0, this.outlethistory.length);
    let primaryoutlethistory = new OutletHistory();
    primaryoutlethistory.outletname = "primary";
    this.outlethistory.push(primaryoutlethistory);
  }

  /**
   * Devuelve el histórico del outlet activo
   *
   * @returns string
   */
  protected getHistory(): string[] {
    const history = this.outlethistory.find(
      (x) => x.outletname == this.outletname
    );
    return history ? history.outlethistory : [];
  }

  /**
   * Limpia el histórico del outlet activo
   */
  public clearHistory() {
    let history = this.outlethistory.find(
      (x) => x.outletname == this.outletname
    );
    if (history) {
      history.outlethistory = [];
    }
    this.jnumstatestrategy.removeAllRoute();
  }

  /**
   * Limpia de la estrategia de reuse la ruta previa del outlet activo
   */
  public clearPreviousRoute() {
    let history = this.outlethistory.find(
      (x) => x.outletname == this.outletname
    );
    if (history) {
      const route = history.outlethistory.pop();
      if (route) {
        this.jnumstatestrategy.removeRoute(route);
      }
    }
  }

  /**
   * Limpia de la estrategia de reuse la ruta indicada
   *
   * @param  {string} strroute
   */
  public clearPreviousRouteFromReuse(strroute: string) {
    let history = this.outlethistory.find(
      (x) => x.outletname == this.outletname
    )?.outlethistory;
    let route = history?.find((x) => x == strroute);
    if (route) {
      this.jnumstatestrategy.removeRoute(route);
    }
  }

  /**
   * Devuelve la última url de navegación.
   *
   * @returns string
   */
  public getPreviousUrl(): string {
    this.history = this.getOutletHistory(this.outletname);
    const previous = this.history[this.history.length - 2] || "/login";
    if (this.outletname == "primary" || this.history.length > 1) {
      let route = this.history.pop();
      if (route) {
        this.jnumstatestrategy.removeRoute(route);
      }
    }

    return previous;
  }

  /**
   * Limpia la última ruta
   */
  public clearLastRoute() {
    this.history.pop();
  }

  /**
   *
   *
   * @param  {string} uri
   */
  public redirectToSameURI(uri: string) {
    let navigationHistory: string[] = this.getHistory();
    this.history = this.getOutletHistory(this.outletname);
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.history = navigationHistory;
      this.router.navigate([uri]);
    });
  }

  /**
   * Devuelve la última ruta del outlet indicado.
   *
   * @param  {string} outletname
   * @returns string
   */
  public getLastRoute(outletname: string | undefined): string {
    let lastroute = null;
    if (outletname) {
      let oulethistory = this.getOutletHistory(outletname);
      if (oulethistory) {
        lastroute = oulethistory[oulethistory.length - 1];
      }
    }
    return lastroute ?? "";
  }

  /**
   * Getter outlethistory
   * @return {OutletHistory[] }
   */
  public get outlethistory(): OutletHistory[] {
    return this._outlethistory;
  }

  /**
   * Setter outlethistory
   * @param {OutletHistory[] } value
   */
  public set outlethistory(value: OutletHistory[]) {
    this._outlethistory = value;
  }

  /**
   * Getter history
   * @return {string[]}
   */
  public get history(): string[] {
    return this._history;
  }

  /**
   * Setter history
   * @param {string[]} value
   */
  public set history(value: string[]) {
    this._history = value;
  }

  /**
   * Getter jnumstatestrategy
   * @return {JnumStateStrategy}
   */
  public get jnumstatestrategy(): JnumStateStrategy {
    return this._jnumstatestrategy;
  }

  /**
   * Setter jnumstatestrategy
   * @param {JnumStateStrategy} value
   */
  public set jnumstatestrategy(value: JnumStateStrategy) {
    this._jnumstatestrategy = value;
  }
}
