import { Sort } from "../component/sort";

export type PageEventType = PageEvent | null | undefined;
export type SortType = Sort | Sort[] | null;
/**
 * Modelo de pagina
 */
export class PageModel {
  private _event!: PageEventType;
  private _sort!: SortType;

  /**
   * Getter event
   * @return {PageEvent}
   */
  public get event(): PageEventType {
    return this._event;
  }

  /**
   * Getter sort
   * @return {Sort }
   */
  public get sort(): SortType {
    return this._sort;
  }

  /**
   * Setter event
   * @param {PageEvent} value
   */
  public set event(value: PageEventType) {
    this._event = value;
  }

  /**
   * Setter sort
   * @param {Sort } value
   */
  public set sort(value: SortType) {
    this._sort = value;
  }
}

export interface PageEvent {
  pageIndex: number;
  pageSize: number;
}
