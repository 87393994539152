import { Injectable } from '@angular/core';
import { NgbDateNativeUTCAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * Adaptador de Fechas
 */
@Injectable()
export class JnumDateAdapter extends NgbDateNativeUTCAdapter {
	/**
	 * @param  {Date} object
	 * @returns NgbDateStruct
	 */
	override fromModel(object: Date): NgbDateStruct | null{
		if (object) {
			let date = new Date(object);
			return date ? {
				year: date.getFullYear(),
				month: date.getMonth() + 1,
				day: date.getDate()
			} : null;
		}
		return null;
	}

}

/**
 * Parser de Fechas
 */
@Injectable()
export class JnumDateParserFormatter {

	readonly DELIMITER = '/';
	/**
	 * @param  {string} value
	 * @returns NgbDateStruct
	 */
	parse(value: string): NgbDateStruct | null {
		if (value) {
			let date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10)
			};
		}
		return null;
	}
	/**
	 * @param  {NgbDateStruct|null} date
	 * @returns string
	 */
	format(date: NgbDateStruct | null): string {
		return date ? (date.day < 10 ? "0" + date.day : date.day) + this.DELIMITER + (date.month < 10 ? "0" + date.month : date.month) + this.DELIMITER + date.year : '';
	}
}