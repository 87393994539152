import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PortalComponent } from "./portal.component";
import { NgxChartsModule } from "@swimlane/ngx-charts"; 
import { TranslateModule } from "@ngx-translate/core"; // Importa TranslateModule

@NgModule({
  declarations: [PortalComponent],
  imports: [
    CommonModule, 
    NgxChartsModule, 
    TranslateModule // Añade el módulo de traducción
  ],
  exports: [PortalComponent], 
})
export class PortalModule {}
