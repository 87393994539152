import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

/**
 * Guard
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  /**
   * Indicador de activación de la guarda
   */
  canActivate() {
    if (sessionStorage.getItem("currentUser")) {
      return true;
    }
    this.router.navigate(["/login"]);
    return false;
  }
}
