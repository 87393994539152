import { Injectable, OnInit } from '@angular/core';
import { BaseService } from 'src/app/jnum-core/services/base.service';
import { TypeMessage } from 'src/app/jnum-core/enum/type-message.enum';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { Subject } from 'rxjs/internal/Subject';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { environment } from 'src/environments/environment'
import { PageEvent } from 'src/app/jnum-core/model/page-model';
import { PaginationPage } from 'src/app/jnum-core/util/pagination';
import { GenericopBean } from 'src/app/jnum-core/model/genericop-bean';
import { Docfile } from 'src/app/customized/comun/docfile/model/docfile';

/**
 * Servicio generado Docfile
 * @implements {OnInit}
 */
@Injectable({
    providedIn: 'root',
})
export class GeneratedDocfileService extends BaseService<Docfile> implements OnInit{

 
  private _docfileCached!: Observable<PaginationPage<Docfile>> | null;      
  private _reloadDocfileSubject: Subject<boolean>= new Subject();  
     
    
  /**
   * @constructor
   */
  constructor() {
    super();
    this.memcollection = "mdocfiles";
    this.url = environment.serviceUrl+'/comun/docfile';
  }
  
  /**
   * @returns void
   */
  ngOnInit(): void {}    
    
    
  /**
     * Get reload Docfile Subject
     * 
   * @returns Observable
   */
  public getReloadDocfileSubject(): Observable<boolean> {
    return this._reloadDocfileSubject.asObservable();
  }

  /**
     * Set reload Docfile Subject
     * 
   * @param  {boolean} reload
   */
  public setReloadDocfileSubject(reload:boolean) {
    this._reloadDocfileSubject.next(reload);
  }
    
  /**
   * Clear the cache
   */    
  override clearCache(){
    this.docfileCached=null;
  }

  /**
   * Get all entities.
   * @returns Observable
   */    
  override getAllEntities(): Observable<any>{
      if (this.memory) {
          return this.http.get(this.MSERVER_URL + this.memcollection);
      } else {
          if(!this.docfileCached || this.isExpiredCache(this.initialCachedTime)){
              this.initialCachedTime=new Date().getTime();
              this.docfileCached = super.getAllEntities();
          }        
          return this.docfileCached??of(null);
      }
  }    
    
                



    /**
     * Getter docfileCached
     * @return {Observable<PaginationPage<Docfile>>}
     */
    public get docfileCached(): Observable<PaginationPage<Docfile>> | null {
        return this._docfileCached;
    }

    /**
     * Setter docfileCached
     * @param {Observable<PaginationPage<Docfile>>} value
     */
    public set docfileCached(value: Observable<PaginationPage<Docfile>> | null) {
        this._docfileCached = value;
    }
}