import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ToasterService } from "src/app/jnum-core/services/toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { TypeMessage } from "../enum/type-message.enum";

@Injectable()
export class ValidationFormService {
  constructor(
    protected toasterService: ToasterService,
    protected translate: TranslateService
  ) {}

  /**
   * Valida los campos de un formulario de una entidad.
   *
   * @param  {FormGroup} formGroup
   * @param  {String} nombreentidad
   */
  validateAllFormFieldsForEntity(formGroup: FormGroup, nombreentidad: String) {
    let fields = "";
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        if (control.status == "INVALID") {
          fields =
            fields +
            "\t" +
            this.translate.instant(nombreentidad + "." + field) +
            "\n";
        }
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
    this.toasterService.showToaster(
      TypeMessage.ERROR_MESSAGE,
      this.translate.instant("global.formko") + "\n" + fields
    );
  }

  /**
   * Valida los campos de un formulario.
   *
   * @param  {FormGroup} formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
