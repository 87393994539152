import { Injectable, TemplateRef } from "@angular/core";
import { ToastComponent } from "src/app/jnum-core/util/toast/toast.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { TypeMessage } from "../enum/type-message.enum";

/**
 * Servicio de toast de mensajes.
 */
@Injectable({ providedIn: "root" })
export class ToasterService {
  private _data: any;
  private _toasts: any[] = [];

  constructor(
    protected translate: TranslateService,
    protected modalService: NgbModal
  ) {}

  /**
   * Abre el toaster dependiendo del tipo de error.
   *
   * @param  {TypeMessage} typeMessage
   * @param  {string} message
   */
  showToaster(typeMessage: TypeMessage, message: string) {
    if (typeMessage === TypeMessage.INFO_MESSAGE) {
      this.data = {
        header: this.translate.instant("toaster.headerinfo"),
        msg: message,
        classname: "bg-success text-light",
        icon: "far fa-check-circle",
        iconclass: "icon-bg-success",
        textclassname: "text-color-success",
      };
    } else if (typeMessage === TypeMessage.WARNING_MESSAGE) {
      this.data = {
        header: this.translate.instant("toaster.headerwarning"),
        msg: message,
        classname: "bg-warning text-light",
        icon: "fas fa-exclamation",
        iconclass: "icon-bg-warning",
        textclassname: "text-color-warning",
      };
    } else {
      this.data = {
        header: this.translate.instant("toaster.headererror"),
        msg: message,
        classname: "bg-danger text-light",
        icon: "far fa-times-circle",
        iconclass: "icon-bg-danger",
        textclassname: "text-color-danger",
      };
    }
    this.openDialog();
  }

  /**
   * Abre el diálogo
   *
   * @returns void
   */
  openDialog(): void {
    const dialogRef = this.modalService.open(ToastComponent);
    dialogRef.componentInstance.data = this.data;
  }

  /**
   * Añade nuevos mensajes al toast.
   *
   * @param  {string|TemplateRef<any>} textOrTpl
   * @param  {any={}} options
   */
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  /**
   * Elimina mensajes del toast.
   *
   * @param  {} toast
   */
  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  /**
   * Getter data
   * @return {any}
   */
  public get data(): any {
    return this._data;
  }

  /**
   * Getter toasts
   * @return {any[] }
   */
  public get toasts(): any[] {
    return this._toasts;
  }

  /**
   * Setter data
   * @param {any} value
   */
  public set data(value: any) {
    this._data = value;
  }

  /**
   * Setter toasts
   * @param {any[] } value
   */
  public set toasts(value: any[]) {
    this._toasts = value;
  }
}
