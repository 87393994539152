<nav class="mb-1 navbar navbar-expand-lg search-toolbar">
  <button
    class="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#buttonBoxSearchvivienda"
    aria-controls="navbarScroll"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="buttonBoxSearchvivienda">
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="nav-item">
        <a
          class="btn btn-primary"
          (click)="searchChart()"
          title="{{ 'global.buscar' | translate }}"
        >
          <em
            class="fas fa-search"
            [attr.aria-label]="'global.buscar' | translate"
          ></em>
        </a>
      </li>
    </ul>
  </div>
</nav>
<div class="mosaic">
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical
      [results]="barChartDataVivienda"
      [view]="view"
      [scheme]="colorScheme"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Vivienda'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [xAxisLabel]="''"
      [yAxisLabel]="''"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical>
  </div>
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical
      [results]="barChartDataHogar"
      [view]="view"
      [scheme]="colorSchemeHogar"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Hogar 1'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [xAxisLabel]="''"
      [yAxisLabel]="''"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical>
  </div>
</div>
<div class="mosaic">
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical-stacked
      [results]="barChartDataHogarSub"
      [view]="view"
      [scheme]="colorSchemeStacked"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Subvencionables'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [xAxisLabel]="''"
      [yAxisLabel]="''"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical
      [results]="barChartDataStates"
      [view]="view"
      [scheme]="colorSchemeCaseStacked"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Estados'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [xAxisLabel]="''"
      [yAxisLabel]="''"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical>
  </div>
</div>
<div class="mosaic">
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical-stacked
      [results]="barChartDataViviendaStacked"
      [view]="view"
      [scheme]="colorSchemeStackedA1"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Hogares'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="true"
      [xAxisLabel]="''"
      [yAxisLabel]="'TS-A1'"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical-stacked
      [results]="barChartDataViviendaStackedA2"
      [view]="view"
      [scheme]="colorSchemeStackedA2"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Hogares'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="true"
      [xAxisLabel]="''"
      [yAxisLabel]="'TS-A2'"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
</div>
<div class="mosaic">
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical-stacked
      [results]="barChartDataViviendaStackedA3"
      [view]="view"
      [scheme]="colorSchemeStackedA3"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Viviendas'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="true"
      [xAxisLabel]="''"
      [yAxisLabel]="'TS-A3'"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical-stacked
      [results]="barChartDataViviendaStackedA4"
      [view]="view"
      [scheme]="colorSchemeStackedA3"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Hogares'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="true"
      [xAxisLabel]="''"
      [yAxisLabel]="'TS-A4'"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
</div>
<div class="mosaic">
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical-stacked
      [results]="barChartDataViviendaStackedA5"
      [view]="view"
      [scheme]="colorSchemeStacked"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Hogares'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="true"
      [xAxisLabel]="''"
      [yAxisLabel]="'TS-A5'"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical-stacked
      [results]="barChartDataViviendaStackedA6"
      [view]="view"
      [scheme]="colorSchemeStacked"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Hogares'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="true"
      [xAxisLabel]="''"
      [yAxisLabel]="'TS-A6'"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
</div>
<div class="mosaic">
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical-stacked
      [results]="barChartDataViviendaStackedB"
      [view]="view"
      [scheme]="colorSchemeStacked"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Hogares'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="true"
      [xAxisLabel]="''"
      [yAxisLabel]="'TS-B'"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
  <div class="mosaic-item" style="flex: 2">
    <ngx-charts-bar-vertical-stacked
      [results]="barChartDataViviendaStackedC"
      [view]="view"
      [scheme]="colorSchemeStacked"
      [xAxis]="false"
      [yAxis]="true"
      [legend]="true"
      [legendTitle]="'Hogares'"
      [showXAxisLabel]="false"
      [showYAxisLabel]="true"
      [xAxisLabel]="''"
      [yAxisLabel]="'TS-C'"
      [gradient]="false"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
</div>
