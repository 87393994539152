import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { isMatch } from "date-fns";
import { Docfile } from "src/app/customized/comun/docfile/model/docfile";
import { GenericBean } from "src/app/jnum-core/model/generic-bean";
import { environment } from "src/environments/environment";
import { TypeMessage } from "../enum/type-message.enum";
import { ToasterService } from "./toaster.service";
import { PageEvent } from "../model/page-model";

const RIGHT = "RIGHT";

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}
/**
 * Servicio de utilidades
 */
@Injectable()
export class UtilService {
  private _modaldata: any;
  private _res: string = "";
  private _pop: boolean = false;
  private _activeOutlet: string = "";

  constructor(
    private toasterService: ToasterService,
    protected translate: TranslateService
  ) {}

  /**
   * Compara entidades para setear un valor en un combo.
   *
   * @param  {T} c1
   * @param  {T} c2
   * @returns boolean
   */
  compareFn<T extends GenericBean>(c1: T, c2: T): boolean {
    let isSame = false;
    if (c1 && c2) {
      if (!(c1.id && c2.id)) {
        isSame =
          c1 && c2 ? JSON.stringify(c1) === JSON.stringify(c2) : c1 === c2;
      } else {
        isSame =
          c1 && c2
            ? JSON.stringify(c1.id) === JSON.stringify(c2.id)
            : c1 === c2;
      }
    }
    return isSame;
  }

  /**
   *
   * @param  {T[]} a
   * @param  {string} field
   * @returns void
   */
  sort<T extends GenericBean>(a: T[], field: string): void {
    a.sort((c1: any, c2: any): number => {
      return c1[field] > c2[field] ? 1 : -1;
    });
  }

  /**
   * Devuelve una pagina vacia.
   *
   * @returns PageEvent
   */
  getEmptyPageEvent(): PageEvent {
    return this.getPageEvent(0, 0);
  }

  /**
   * Pagina por defecto
   *
   * @returns PageEvent
   */
  getDefaultPageEvent(): PageEvent {
    return this.getPageEvent(0, environment.pageSize);
  }

  /**
   * Pagina por defecto para detalle
   *
   * @returns PageEvent
   */
  getDefaultDetallePageEvent(): PageEvent {
    return this.getPageEvent(0, environment.detallePageSize);
  }

  /**
   * Crea un PageEvent
   *
   * @returns PageEvent
   */
  getPageEvent(pageIndex: number, pageSize: number): PageEvent {
    return {
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
  }

  /**
   * Devuelve el fichero contenido en un docfile.
   *
   * @param  {Docfile} data
   */
  getFileFromBase64String(data: Docfile) {
    const blob = this.base64toBlob(data.fichero, data.filetype);
    if (window?.navigator?.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, data.filename);
    } else {
      let a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = data.filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  /**
   * Transforma un fichero en base64 a un Blob.
   *
   * @param  {} base64Data
   * @param  {} contentType
   */
  base64toBlob(base64Data: string, contentType: string) {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Devuelve una fecha en millisegundos
   * @param  {any} date
   * @returns number
   */
  getMilliSeconds(date: any): number {
    let dateNew = new Date(date);
    return dateNew.getTime();
  }

  /**
   * Compara dos fechas
   *
   * @param  {Date} fecha1
   * @param  {Date} fecha2
   * @returns number
   */
  compararFechas(fecha1: Date, fecha2: Date): number {
    if (fecha1 > fecha2) {
      return 1;
    } else if (fecha1 < fecha2) {
      return -1;
    } else {
      return 0;
    }
  }

  /**
   * Devuelve la diferencia en meses entre dos fechas.
   *
   * @param  {Date} date1
   * @param  {Date} date2
   * @returns number
   */
  obtenerDiferenciaMeses(date1: Date, date2: Date): number {
    let monthDate1: number = date1.getMonth();
    let yearDate1: number = date1.getFullYear();
    let monthDate2: number = date2.getMonth();
    let yearDate2: number = date2.getFullYear();

    let diffA = yearDate2 - yearDate1;

    let diffM = 12 * Math.abs(diffA) + monthDate2 - monthDate1;
    return Math.abs(diffM);
  }

  /**
   * Comprueba si un objeto es null o undefined.
   *
   * @param  {any} object
   */
  public isNullOrUndefined(object: any) {
    return object === null || object === undefined;
  }

  /**
   * Comprueba si un objeto es null, undefined o vacio.
   * @param  {any} object
   */
  public isNullOrUndefinedOrEmpty(object: any) {
    return this.isNullOrUndefined(object) || "" === object;
  }

  /**
   * Comprueba si una lista está vacía.
   *
   * @param  {any[]} array
   * @returns boolean
   */
  isEmptyList(array: any[]): boolean {
    return !(array && array.length > 0);
  }

  /**
   * Convierte un valor en una fecha.
   *
   * @param  {any} value
   * @returns Date
   */
  getDateFromValue(value: any): Date {
    if (!this.isNullOrUndefinedOrEmpty(value) && !(value instanceof Date)) {
      return new Date(value);
    }
    return value;
  }

  /**
   * Devuelve un number formateado con el numero de decimales indicado.
   *
   * @param  {number} value
   * @param  {number} decimals
   * @returns any
   */
  getFormatedValue(value: number, decimals: number): any {
    let returnValue: string = "0";
    if (!this.isNullOrUndefined(value)) {
      returnValue = value.toString();
      if (returnValue.indexOf(".") == -1) {
        returnValue =
          returnValue + "," + this.fillWithCharacters("0", "", decimals, RIGHT);
      } else {
        let ndecimals = returnValue.substr(returnValue.indexOf(".") + 1).length;
        returnValue = returnValue.replace(".", ",");
        if (ndecimals < decimals) {
          returnValue =
            returnValue +
            this.fillWithCharacters("0", "", decimals - ndecimals, RIGHT);
        }
      }
    } else {
      returnValue =
        returnValue + "," + this.fillWithCharacters("0", "", decimals, RIGHT);
    }
    return returnValue;
  }

  /**
   * Rellena un string con n caracteres en la dirección indicada.
   *
   * @param  {string} character
   * @param  {string} stringToFill
   * @param  {number} length
   * @param  {string} direction
   * @returns string
   */
  fillWithCharacters(
    character: string,
    stringToFill: string,
    length: number,
    direction: string
  ): string {
    let stringOfCharacters = "";
    let stringToFillLength = stringToFill.length;
    for (let nIdx = stringToFillLength; nIdx < length; nIdx++) {
      stringOfCharacters += character;
    }
    if (RIGHT === direction) {
      return stringToFill + stringOfCharacters;
    } else {
      return stringOfCharacters + stringToFill;
    }
  }

  dateMask(event: any) {
    const inputLength = event.target.value.length;
    if (inputLength != 0) {
      const isBackspaceOrDelete = event.keyCode === 8 || event.keyCode === 46;

      if (isBackspaceOrDelete) {
        const lastCharacter = event.target.value;
        if (inputLength === 2 || inputLength === 5) {
          event.target.value = event.target.value.substring(0, inputLength - 1);
        }
      } else {
        if (inputLength === 2 || inputLength === 5) {
          event.target.value += "/";
        }
      }

      if (inputLength > 10) {
        event.target.value = event.target.value.substring(0, 10);
      }
    }
  }

  encodeSpecialCaracters(charToEncode: any) {
    if (
      !this.isNullOrUndefinedOrEmpty(charToEncode) &&
      typeof charToEncode == "string" &&
      !this.isEncoded(charToEncode)
    ) {
      return encodeURIComponent(charToEncode);
    } else {
      return charToEncode;
    }
  }

  isEncoded(str: string): boolean {
    return typeof str == "string" && decodeURIComponent(str) !== str;
  }

  getEntityId(jsonId: any): string {
    let embeddedid: string = "";
    if (typeof jsonId === "object") {
      embeddedid = Object.values(jsonId)
        .map((id) => {
          return this.isDate(id) ? this.getMilliSeconds(id) : id;
        })
        .join("/");
    } else {
      embeddedid = jsonId;
    }
    return this.isNullOrUndefinedOrEmpty(embeddedid) ? jsonId : embeddedid;
  }

  isDate(id: any): boolean {
    let match =
      isMatch(id, "yyyy-MM-dd'T'HH:mm:ss.SSS") ||
      isMatch(id, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") ||
      isMatch(id, "yyyy-MM-dd'T'HH:mm:ss") ||
      isMatch(id, "dd/MM/yyyy");
    return match;
  }

  showError(codError: string, params?: Object) {
    this.showErrorWithParams(TypeMessage.ERROR_MESSAGE, codError, params);
  }

  showInfo(codError: string, params?: Object) {
    this.showErrorWithParams(TypeMessage.INFO_MESSAGE, codError, params);
  }

  showWarning(codError: string, params?: Object) {
    this.showErrorWithParams(TypeMessage.WARNING_MESSAGE, codError, params);
  }

  showQuestion(codError: string, params?: Object) {
    this.showErrorWithParams(TypeMessage.QUESTION_MESSAGE, codError, params);
  }

  showErrorWithParams(type: TypeMessage, codError: string, params?: Object) {
    let message = this.translate.instant(codError, params);
    this.toasterService.showToaster(
      type,
      this.isNullOrUndefinedOrEmpty(message) ? codError : message
    );
  }

  /**
   * Getter modaldata
   * @return {any}
   */
  public get modaldata(): any {
    return this._modaldata;
  }

  /**
   * Getter res
   * @return {string}
   */
  public get res(): string {
    return this._res;
  }

  /**
   * Getter pop
   * @return {boolean }
   */
  public get pop(): boolean {
    return this._pop;
  }

  /**
   * Getter activeOulet
   * @return {string}
   */
  public get activeOutlet(): string {
    return this._activeOutlet;
  }

  /**
   * Setter modaldata
   * @param {any} value
   */
  public set modaldata(value: any) {
    this._modaldata = value;
  }

  /**
   * Setter res
   * @param {string} value
   */
  public set res(value: string) {
    this._res = value;
  }

  /**
   * Setter pop
   * @param {boolean } value
   */
  public set pop(value: boolean) {
    this._pop = value;
  }

  /**
   * Setter activeOulet
   * @param {string} value
   */
  public set activeOutlet(value: string) {
    this._activeOutlet = value;
  }
}
