import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";
import { tap } from "rxjs/operators";
import { GenericFilter } from "src/app/jnum-core/filter/generic-filter";
import { AppInjector } from "src/app/jnum-core/injector/app.injector";
import { GenericBean } from "src/app/jnum-core/model/generic-bean";
import { MemoryList } from "src/app/jnum-core/model/memorylist";
import { PageEvent, PageModel } from "src/app/jnum-core/model/page-model";
import { Token } from "src/app/jnum-core/model/token";
import { PaginationPage } from "src/app/jnum-core/util/pagination";
import { environment } from "src/environments/environment";

import { Docfile } from "src/app/customized/comun/docfile/model/docfile";
import { UtilService } from "./util.service";
import { Sort } from "../component/sort";

type SortType = Sort | Sort[] | null;

@Injectable()
export class BaseService<T extends GenericBean> {
  protected http: HttpClient;
  protected utilService: UtilService;
  protected translate: TranslateService;
  protected router: Router;
  protected spinner: NgxSpinnerService;

  private _token!: Token;
  private _pageIndex: number = 0;
  private _pageSize: number = 2;
  private _url: string = "";
  private _memcollection: string = "";
  private _isSpinnerEnable: boolean = true;
  private _totalesBaseSubject!: BehaviorSubject<any>;
  public totalesBaseObs!: Observable<any>;
  private _currentPageModel: PageModel = new PageModel();
  private _modaldata: any;
  private _sort!: SortType;
  private _event!: PageEvent | null;
  private _memory: boolean = false;
  private _showMessage: boolean = true;
  private _initialCachedTime: number = environment.timeToCache;
  private _modifiedForm: boolean = false;
  private _isLongprocess: boolean = false;
  private _activeModal!: NgbActiveModal;
  private _iseditable!: boolean;
  private _isdetalle!: boolean;
  private _isnewsearch!: boolean;

  MSERVER_URL: string = "/api/";

  emptyPage!: {
    number: 0;
    size: 0;
  };

  constructor() {
    const injector = AppInjector.getInjector();
    this.http = injector.get(HttpClient);
    this.utilService = injector.get(UtilService);
    this.translate = injector.get(TranslateService);
    this.router = injector.get(Router);
    this.spinner = injector.get(NgxSpinnerService);
    this.clearCache();
  }

  /**
   * Limpia los datos del pageModel
   */
  clearPageModelData() {
    this._sort = null;
    this._event = null;
  }

  clearCache() {}

  updateReferences(entity: T) {}
  /**
   * Comprueba si ha expirado la cache.
   *
   * @param  {number} initialCachedTime
   * @returns boolean
   */
  isExpiredCache(initialCachedTime: number): boolean {
    return new Date().getTime() - initialCachedTime > environment.timeToCache;
  }

  /** Getter httpHeaders.
   *
   * @returns HttpHeaders
   */
  getHttpHeaders(): HttpHeaders {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser") ?? "");
    this.token = currentUser && currentUser.token;
    if (this.token) {
      return new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + this.token.jwtToken,
      });
    }
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });
  }

  /**
   * @param  {string} url
   * @returns Observable
   */
  httpGet(url: string): Observable<any> {
    return this.http.get<any>(url, { headers: this.getHttpHeaders() });
  }

  /**
   * @param  {string} url
   * @param  {any} data
   * @returns Observable
   */
  httpPut(url: string, data: any): Observable<any> {
    return this.http.put(url, data, { headers: this.getHttpHeaders() });
  }

  /**
   * Devuelve todas las entidades.
   *
   * @param  {PageEvent} event?
   * @returns Observable
   */
  getAllEntities(event?: PageEvent): Observable<PaginationPage<T>> {
    if (!this.memory) {
      return this.http.get<PaginationPage<T>>(this.buildUrl(this.url, event), {
        headers: this.getHttpHeaders(),
      });
    } else {
      return this.http.get<PaginationPage<T>>(
        this.MSERVER_URL + this.memcollection
      );
    }
  }

  /**
   * Devuelve todas las entidades en memoria.
   *
   * @param  {PageEvent} event?
   * @returns Observable
   */
  getAllEntitiesFromMemory(event?: PageEvent): Observable<T[]> {
    return this.http.get<T[]>(this.MSERVER_URL + this.memcollection);
  }

  /**
   * Devuelve una entidad. Si el memory es true la devuelve de la memoria sino del back
   * @param  {string} id
   * @returns Observable
   */
  getEntity(id: string): Observable<T> {
    if (!this.memory) {
      const getEntityUrl = `${this.url}/${id}`;
      return this.http.get<T>(getEntityUrl, { headers: this.getHttpHeaders() });
    } else {
      return this.http.get<T>(
        `${this.MSERVER_URL + this.memcollection}/${this.replaceAll(
          id,
          "/",
          "-"
        )}`
      );
    }
  }

  /**
   * Añade una nueva entidad.
   *
   * @param  {T} entity
   * @param  {boolean=true} showMessage
   * @param  {boolean=true} spinnerActivate
   * @returns Observable
   */
  addEntity(
    entity: T,
    showMessage: boolean = true,
    spinnerActivate: boolean = true
  ): Observable<T> {
    this.clearCache();
    if (spinnerActivate) {
      this.startSpinner();
    }
    if (!this.memory) {
      return this.http
        .post<T>(this.url, entity, { headers: this.getHttpHeaders() })
        .pipe(
          tap((entitynew) => {
            this.processAddEntityWithoutError(
              entitynew,
              spinnerActivate,
              showMessage
            );
          })
        );
    } else {
      return this.addEntityToMemory(entity, spinnerActivate);
    }
  }

  /**
   * Procesa la respuesta del añadir entidad si no hay error.
   *
   * @param  {boolean} spinnerActivate
   * @param  {boolean} showMessage
   */
  protected processAddEntityWithoutError(
    entity: T,
    spinnerActivate: boolean,
    showMessage: boolean
  ) {
    if (spinnerActivate) {
      this.stopSpinner();
    }
    if (showMessage) {
      this.utilService.showInfo("toaster.addok");
    }
  }

  /**
   * Añade una nueva entidad en memoria.
   *
   * @param  {T} entity
   * @param  {boolean} spinnerActivate
   */
  addEntityToMemory(entity: T, spinnerActivate: boolean) {
    this.guid(entity);
    this.updateReferences(entity);
    return this.http
      .post<T>(`${this.MSERVER_URL + this.memcollection}`, entity)
      .pipe(
        tap((_) => {
          if (spinnerActivate) {
            this.stopSpinner();
          }
        })
      );
  }

  /**
   * Añade una lista de entidades en memoria.
   *
   * @param  {MemoryList} entities
   * @param  {boolean=true} showMessage
   * @param  {boolean=true} spinnerActivate
   * @returns Observable
   */
  addAllEntitiesToMemory(
    entities: MemoryList,
    showMessage: boolean = true,
    spinnerActivate: boolean = true
  ): Observable<T> {
    if (spinnerActivate) {
      this.startSpinner();
    }
    this.addGuidAndupdateReferencesToCollection(entities);
    return this.http
      .post<T>(`${this.MSERVER_URL + this.memcollection}`, entities)
      .pipe(
        tap((_) => {
          if (spinnerActivate) {
            this.stopSpinner();
          }
        })
      );
  }
  /**
   * Añade un id a una lista de entidades y actualiza sus referencias.
   *
   * @param  {MemoryList} memoryitem
   */
  addGuidAndupdateReferencesToCollection(memoryitem: MemoryList) {
    let entities = memoryitem.items;
    for (let entity of entities) {
      this.guid(entity);
      this.updateReferences(entity);
    }
  }

  /**
   * Actualiza una entidad. Si memory es true la actualiza en memoria, en caso contrario
   * la actualiza en el back.
   *
   * @param  {T} entity
   * @param  {boolean=true} showMessage
   * @param  {boolean=true} spinnerActivate
   * @returns Observable
   */
  updateEntity(
    entity: T,
    id: any,
    showMessage: boolean = true,
    spinnerActivate: boolean = true
  ): Observable<Object> {
    this.clearCache();
    if (spinnerActivate) {
      this.startSpinner();
    }
    if (!this.memory) {
      const getEntityUrl = `${this.url}/${id}`;
      return this.http
        .put(getEntityUrl, entity, { headers: this.getHttpHeaders() })
        .pipe(
          tap((_) => {
            if (spinnerActivate) {
              this.stopSpinner();
            }
            if (showMessage && this.showMessage) {
              this.utilService.showInfo("toaster.editok");
            }
            this.showMessage = true;
          })
        );
    } else {
      return this.updateEntityToMemory(entity, spinnerActivate, showMessage);
    }
  }

  /**
   * Actualiza una entidad en memoria
   *
   * @param  {T} entity
   * @param  {boolean} spinnerActivate
   * @param  {boolean} showMessage
   */
  updateEntityToMemory(
    entity: T,
    spinnerActivate: boolean,
    showMessage: boolean
  ) {
    this.updateReferences(entity);
    return this.http
      .put<T>(`${this.MSERVER_URL + this.memcollection}/${entity.id}`, entity)
      .pipe(
        tap((_) => {
          if (spinnerActivate) {
            this.stopSpinner();
          }
          if (showMessage && this.showMessage) {
            this.utilService.showInfo("toaster.editok");
          }
        })
      );
  }
  /**
   * Elimina una entidad. Si memory es true la elimina en memoria, en caso contrario
   * la elimina en el back.
   *
   * @param  {string} id
   * @param  {boolean=true} showMessage
   * @param  {boolean=true} spinnerActivate
   * @returns Observable
   */
  deleteEntity(
    id: string,
    showMessage: boolean = true,
    spinnerActivate: boolean = true
  ): Observable<T> {
    this.clearCache();
    if (spinnerActivate) {
      this.startSpinner();
    }
    if (!this.memory) {
      const deleteUrl = `${this.url}/${id}`;
      return this.http
        .delete<T>(deleteUrl, { headers: this.getHttpHeaders() })
        .pipe(
          tap((_) => {
            if (spinnerActivate) {
              this.stopSpinner();
            }
            if (showMessage && this.showMessage) {
              this.utilService.showInfo("toaster.deleteok");
            }
          })
        );
    } else {
      return this.deleteEntityFromMemory(id, spinnerActivate, showMessage);
    }
  }

  /**
   * Elimina una entidad en memoria.
   *
   * @param  {string} id
   * @param  {boolean} spinnerActivate
   * @param  {boolean} showMessage
   * @returns Observable
   */
  deleteEntityFromMemory(
    id: string,
    spinnerActivate: boolean,
    showMessage: boolean
  ): Observable<T> {
    return this.http
      .delete<T>(
        `${this.MSERVER_URL + this.memcollection}/${this.replaceAll(
          id,
          "/",
          "-"
        )}`
      )
      .pipe(
        tap((_) => {
          if (spinnerActivate) {
            this.stopSpinner();
          }
          if (showMessage && this.showMessage) {
            this.utilService.showInfo("toaster.deleteok");
          }
        })
      );
  }
  /**
   * Búsqueda de entidades por filtro.
   *
   * @param  {GenericFilter} filter
   * @param  {number} pageSize
   * @param  {PageEvent} event?
   * @param  {Sort|Sort[]} sort?
   * @param  {boolean=false} disable
   * @returns Observable
   */
  searchEntitiesByFilter(
    filter: GenericFilter,
    pageSize: number,
    event?: PageEvent | null,
    sort?: SortType,
    disable: boolean = false
  ): Observable<PaginationPage<T>> {
    if (!disable) {
      this.startSpinner();
    }
    if (!event) {
      event = this.getEvent(pageSize);
    }
    return this.http
      .post<PaginationPage<T>>(
        this.buildUrl(this.url + "/filter", event, this.getMySort(sort)),
        filter,
        { headers: this.getHttpHeaders() }
      )
      .pipe(
        tap((_) => {
          if (!disable) {
            this.stopSpinner();
          }
        })
      );
  }

  /**
   * @param  {PageEvent} event
   * @param  {number} pageSize
   */
  private getEvent(pageSize: number): PageEvent {
    return this.utilService.getPageEvent(environment.firstPageIndex, pageSize);
  }

  /**
   * @param  {Sort|Sort[]} sort
   */
  private getMySort(sort: Sort | Sort[] | undefined | null) {
    let mySort;
    if (sort) {
      if (!Array.isArray(sort)) {
        mySort = {
          active: sort.active,
          direction: sort.direction,
        };
      } else {
        mySort = sort;
      }
    }
    return mySort;
  }

  /**
   * Busqueda de entidades por filtro sin paginación.
   *
   * @param  {GenericFilter} filter
   * @param  {Sort} sort?
   * @returns Observable
   */
  searchEntitiesByFilterNoPaginated(
    filter: GenericFilter,
    sort?: Sort | Sort[] | null
  ): Observable<PaginationPage<T>> {
    return this.http.post<PaginationPage<T>>(
      this.buildUrl(this.url + "/filter", null, sort),
      filter,
      { headers: this.getHttpHeaders() }
    );
  }

  /**
   * Genera un identificador para una entidad en memoria.
   *
   * @param  {T} entity
   */
  guid(entity: T) {
    entity.id =
      this.s4() +
      this.s4() +
      "-" +
      this.s4() +
      "-" +
      this.s4() +
      "-" +
      this.s4() +
      "-" +
      this.s4() +
      this.s4() +
      this.s4();
  }

  /**
   * Genera un string aleatorio.
   *
   * @returns string
   */
  s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  /**
   * Reemplaza todas las ocurrencias en un string.
   *
   * @param  {string} str
   * @param  {string} find
   * @param  {string} replace
   */
  replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, "g"), replace);
  }

  /**
   * Construye la url de llamada a un servicio.
   *
   * @param  {string} urlBase
   * @param  {PageEvent} event?
   * @param  {Sort|Sort[]} sort?
   * @returns string
   */
  protected buildUrl(
    urlBase: string,
    event?: PageEvent | null,
    sort?: Sort | Sort[] | null
  ): string {
    if (!Array.isArray(sort)) {
      if (event && sort && sort.active) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        return (
          urlBase +
          "?page=" +
          this.pageIndex +
          "&size=" +
          this.pageSize +
          "&sort=" +
          sort.active +
          "," +
          sort.direction
        );
      } else if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        return urlBase + "?page=" + this.pageIndex + "&size=" + this.pageSize;
      } else if (sort && sort.active) {
        return urlBase + "?sort=" + sort.active + "," + sort.direction;
      }
    } else {
      if (event && sort) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        return (
          urlBase +
          "?page=" +
          this.pageIndex +
          "&size=" +
          this.pageSize +
          "&" +
          this.getSortedFields(sort)
        );
      } else if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        return urlBase + "?page=" + this.pageIndex + "&size=" + this.pageSize;
      } else if (sort) {
        return urlBase + "?" + this.getSortedFields(sort);
      }
    }
    return urlBase;
  }

  /**
   * Devuelve el listado de campos de ordenación.
   *
   * @param  {any[]} sorterdFields
   */
  getSortedFields(sorterdFields: any[]) {
    let sortUrl = "";
    if (sorterdFields && sorterdFields.length > 0) {
      let numfields = sorterdFields.length;
      for (let nIdx = 0; nIdx < numfields; nIdx++) {
        sortUrl =
          sortUrl +
          ("sort=" +
            sorterdFields[nIdx].active +
            "," +
            sorterdFields[nIdx]._direction);
        if (nIdx < numfields) {
          sortUrl = sortUrl + "&";
        }
      }
    }
    return sortUrl;
  }

  /**
   * Genera el pdf del listado de una entidad.
   *
   * @param  {GenericFilter} filter
   * @param  {PageModel} pagemodel?
   * @returns Observable
   */
  generarPdf(
    filter: GenericFilter,
    pagemodel?: PageModel
  ): Observable<Docfile> {
    this.startSpinner();
    let url = pagemodel
      ? this.buildUrl(this.url + "/pdf/filter", pagemodel.event, pagemodel.sort)
      : this.url + "/pdf/filter";
    return this.http
      .post<Docfile>(url, filter, { headers: this.getHttpHeaders() })
      .pipe(
        tap((_) => {
          this.stopSpinner();
        })
      );
  }

  /**
   * Genera el excel del listado de una entidad.
   *
   * @param  {GenericFilter} filter
   * @param  {PageModel} pagemodel?
   * @returns Observable
   */
  generarExcel(
    filter: GenericFilter,
    pagemodel?: PageModel
  ): Observable<Docfile> {
    this.startSpinner();
    let url = pagemodel
      ? this.buildUrl(
          this.url + "/excel/filter",
          pagemodel.event,
          pagemodel.sort
        )
      : this.url + "/excel/filter";
    return this.http
      .post<Docfile>(url, filter, { headers: this.getHttpHeaders() })
      .pipe(
        tap((_) => {
          this.stopSpinner();
        })
      );
  }

  /**
   * Comprueba si un usuario puede añadir una nueva entidad.
   *
   * @returns Observable
   */
  canDoAdd(): Observable<any> {
    return this.http.get(this.url + "/candoadd", {
      headers: this.getHttpHeaders(),
    });
  }

  /**
   * Comprueba si un usuario puede editar una entidad.
   *
   * @returns Observable
   */
  canDoEdit(): Observable<any> {
    return this.http.get(this.url + "/candoedit", {
      headers: this.getHttpHeaders(),
    });
  }

  /**
   * Comprueba si un usuario puede eliminar una entidad.
   *
   * @returns Observable
   */
  canDoDelete(): Observable<any> {
    return this.http.get(this.url + "/candodelete", {
      headers: this.getHttpHeaders(),
    });
  }

  /**
   * Inicializa el spinner
   */
  startSpinner() {
    if (this.isSpinnerEnable && !this.isLongprocess) {
      this.spinner.show();
    }
  }

  /**
   * Para el spinner
   */
  stopSpinner() {
    if (!this.isLongprocess) {
      this.spinner.hide();
    }
  }

  /**
   * Inicializa el spinner en rpocesos largos
   */
  startLongProcessSpinner() {
    this.isLongprocess = true;
    if (this.isSpinnerEnable) {
      this.spinner.show();
    }
  }

  /**
   * Para el spinner en procesos largos
   */
  stopLongProcessSpinner() {
    this.isLongprocess = false;
    this.spinner.hide();
  }

  /**
   * Habilita o deshabilita el spinner.
   *
   * @param  {boolean} isSpinnerEnable
   */
  setSpinnerEnable(isSpinnerEnable: boolean) {
    this.isSpinnerEnable = isSpinnerEnable;
  }

  /**
   * Establece al pagemodel actual.
   *
   * @param  {PageModel} currentPageModel
   */
  public setCurrentPageModel(currentPageModel: PageModel) {
    this._currentPageModel = currentPageModel;
  }

  /**
   * Getter pageModel.
   *
   * @returns PageModel
   */
  public getCurrentPageModel(): PageModel {
    return this._currentPageModel;
  }

  /**
   * Getter token
   * @return {Token}
   */
  public get token(): Token {
    return this._token;
  }

  /**
   * Getter pageIndex
   * @return {number }
   */
  public get pageIndex(): number {
    return this._pageIndex;
  }

  /**
   * Getter pageSize
   * @return {number }
   */
  public get pageSize(): number {
    return this._pageSize;
  }

  /**
   * Getter url
   * @return {string}
   */
  public get url(): string {
    return this._url;
  }

  /**
   * Getter memcollection
   * @return {string}
   */
  public get memcollection(): string {
    return this._memcollection;
  }

  /**
   * Getter isSpinnerEnable
   * @return {boolean }
   */
  public get isSpinnerEnable(): boolean {
    return this._isSpinnerEnable;
  }

  /**
   * Getter totalesBaseSubject
   * @return {BehaviorSubject<any>}
   */
  public get totalesBaseSubject(): BehaviorSubject<any> {
    return this._totalesBaseSubject;
  }

  /**
   * Getter modaldata
   * @return {any}
   */
  public get modaldata(): any {
    return this._modaldata;
  }

  /**
   * Getter sort
   * @return {Sort }
   */
  public get sort(): Sort | Sort[] | null {
    return this._sort;
  }

  /**
   * Getter event
   * @return {PageEvent}
   */
  public get event(): PageEvent | null {
    return this._event;
  }

  /**
   * Setter token
   * @param {Token} value
   */
  public set token(value: Token) {
    this._token = value;
  }

  /**
   * Setter pageIndex
   * @param {number } value
   */
  public set pageIndex(value: number) {
    this._pageIndex = value;
  }

  /**
   * Setter pageSize
   * @param {number } value
   */
  public set pageSize(value: number) {
    this._pageSize = value;
  }

  /**
   * Setter url
   * @param {string} value
   */
  public set url(value: string) {
    this._url = value;
  }

  /**
   * Setter memcollection
   * @param {string} value
   */
  public set memcollection(value: string) {
    this._memcollection = value;
  }

  /**
   * Setter isSpinnerEnable
   * @param {boolean } value
   */
  public set isSpinnerEnable(value: boolean) {
    this._isSpinnerEnable = value;
  }

  /**
   * Setter totalesBaseSubject
   * @param {BehaviorSubject<any>} value
   */
  public set totalesBaseSubject(value: BehaviorSubject<any>) {
    this._totalesBaseSubject = value;
  }

  /**
   * Setter modaldata
   * @param {any} value
   */
  public set modaldata(value: any) {
    this._modaldata = value;
  }

  /**
   * Setter sort
   * @param {Sort } value
   */
  public set sort(value: Sort | Sort[] | null) {
    this._sort = value;
  }

  /**
   * Setter event
   * @param {PageEvent} value
   */
  public set event(value: PageEvent | null) {
    this._event = value;
  }

  /**
   * Getter memory
   * @return {boolean }
   */
  public get memory(): boolean {
    return this._memory;
  }

  /**
   * Getter showMessage
   * @return {boolean }
   */
  public get showMessage(): boolean {
    return this._showMessage;
  }

  /**
   * Setter memory
   * @param {boolean } value
   */
  public set memory(value: boolean) {
    this._memory = value;
  }

  /**
   * Setter showMessage
   * @param {boolean } value
   */
  public set showMessage(value: boolean) {
    this._showMessage = value;
  }

  /**
   * Getter initialCachedTime
   * @return {number}
   */
  public get initialCachedTime(): number {
    return this._initialCachedTime;
  }

  /**
   * Getter modifiedForm
   * @return {boolean}
   */
  public get modifiedForm(): boolean {
    return this._modifiedForm;
  }

  /**
   * Setter initialCachedTime
   * @param {number} value
   */
  public set initialCachedTime(value: number) {
    this._initialCachedTime = value;
  }

  /**
   * Setter modifiedForm
   * @param {boolean} value
   */
  public set modifiedForm(value: boolean) {
    this._modifiedForm = value;
  }

  /**
   * Getter isLongprocess
   * @return {boolean }
   */
  public get isLongprocess(): boolean {
    return this._isLongprocess;
  }

  /**
   * Setter isLongprocess
   * @param {boolean } value
   */
  public set isLongprocess(value: boolean) {
    this._isLongprocess = value;
  }

  /**
   * Getter activeModal
   * @return {NgbActiveModal}
   */
  public get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  /**
   * Setter activeModal
   * @param {NgbActiveModal} value
   */
  public set activeModal(activeModal: NgbActiveModal) {
    if (activeModal) {
      this._activeModal = activeModal;
    }
  }

  /**
   * Getter userCached
   * @return {Observable<PaginationPage<User>>}
   */
  public get iseditable(): boolean {
    return this._iseditable;
  }

  /**
   * Setter userCached
   * @param {Observable<PaginationPage<User>>} value
   */
  public set iseditable(value: boolean) {
    this._iseditable = value;
  }

  /**
   * Getter userCached
   * @return {Observable<PaginationPage<User>>}
   */
  public get isdetalle(): boolean {
    return this._isdetalle;
  }

  /**
   * Setter userCached
   * @param {Observable<PaginationPage<User>>} value
   */
  public set isdetalle(value: boolean) {
    this._isdetalle = value;
  }

  /**
   * Getter userCached
   * @return {Observable<PaginationPage<User>>}
   */
  public get isnewsearch(): boolean {
    return this._isnewsearch;
  }

  /**
   * Setter userCached
   * @param {Observable<PaginationPage<User>>} value
   */
  public set isnewsearch(value: boolean) {
    this._isnewsearch = value;
  }
}
