import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Icons } from "src/app/jnum-core/model/icons";

/**
 * Servicio de gestión de iconos SVG
 */
@Injectable({
  providedIn: "root",
})
export class IconService {
  constructor(protected domSanitizer: DomSanitizer) {}

  /**
   * Registra los iconos desde una determinada ruta.
   *
   * @returns void
   */
  public registerIcons(): void {
    this.loadIcons(Object.values(Icons), "assets/svg/icons");
  }

  /**
   * Carga los iconos en el registro.
   *
   * @param  {string[]} iconKeys
   * @param  {string} iconUrl
   * @returns void
   */
  protected loadIcons(iconKeys: string[], iconUrl: string): void {
    /*iconKeys.forEach((key) => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${iconUrl}/${key}.svg`
        )
      );
    });*/
  }
}
