import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from "src/app/jnum-core/model/user";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }),
};
const registerUrl = environment.serviceUrl + "/jwt/sign-up";

/**
 * Servicio de registro de usuario.
 */
@Injectable()
export class RegisterService {
  constructor(protected http: HttpClient) {}

  /**
   * Registro de usuario.
   *
   * @param  {User} userToRegister
   * @returns Observable
   */
  register(userToRegister: User): Observable<User> {
    return this.http.post<User>(registerUrl, userToRegister, httpOptions);
  }
}
