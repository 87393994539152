import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/jnum-core/services/authentication.service";
import { AuthorizationService } from "src/app/jnum-core/services/authorization.service";
import { DialogPasswordComponent } from "src/app/jnum-core/password/dialog-password.component";
import { TabService } from "src/app/jnum-core/services/tab.service";
import { NavItem } from "src/app/jnum-core/navbar/nav-item";
import { environment } from "src/environments/environment";
import { UtilService } from "../services/util.service";
import { MODAL_OPTIONS } from "src/app/jnum-core/constants/jnum-constants";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @Output() changemenu: EventEmitter<any> = new EventEmitter<any>();
  private _navItems: NavItem[] = [];
  private _tabs: NavItem[] = [];
  private _password: string = "";
  private _newpassword: string = "";
  private _confirmpassword: string = "";
  private _currentUser: boolean = false;
  private _isauthorized: boolean = false;
  private _logoCabeceraMenu: string = "assets/images/jnum.png";
  
  constructor(
    protected modalService: NgbModal,
    protected translate: TranslateService,
    protected authenticationService: AuthenticationService,
    protected authorizationService: AuthorizationService,
    protected router: Router,
    private tabService: TabService,
    private utilService: UtilService
  ) {
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    });
    const language = sessionStorage.getItem("language")
      ? sessionStorage.getItem("language")
      : translate.getDefaultLang();
    translate
      .use(language ?? environment.defaultLocale)
      .subscribe((res) => this.showItems());
  }

  openTab(navitem: NavItem) {
    if (this.tabService.getNumTabs() > environment.maxNumTabs) {
      this.utilService.showInfo("error.nummaxtabs", {
        maxtabs: environment.maxNumTabs,
      });
      return;
    }
    sessionStorage.setItem("newRoute", "true");
    this.tabService.addTab(navitem);
    this.tabService.notifyOpenTab(navitem);
  }

  ngOnInit() {
    this.tabs = [
              {
          id: 'menu.seguridad.user',
          displayName: this.translate.instant('menu.seguridad.user'),
          outlet: 'user',
          module: 'seguridad',
          permission: 'all_ent_seguridad_user',
          hasPermission: this.authorizationService.hasPermission('all_ent_seguridad_user'),
          route: '/seguridad/user/userlist'
        },
        {
          id: 'menu.seguridad.rol',
          displayName: this.translate.instant('menu.seguridad.rol'),
          outlet: 'rol',
          module: 'seguridad',
          permission: 'all_ent_seguridad_rol',
          hasPermission: this.authorizationService.hasPermission('all_ent_seguridad_rol'),
          route: '/seguridad/rol/rollist'
        },
        {
          id: 'menu.seguridad.grupopermiso',
          displayName: this.translate.instant('menu.seguridad.grupopermiso'),
          outlet: 'grupopermiso',
          module: 'seguridad',
          permission: 'all_ent_seguridad_grupoPermiso',
          hasPermission: this.authorizationService.hasPermission('all_ent_seguridad_grupoPermiso'),
          route: '/seguridad/grupopermiso/grupopermisolist'
        },
        {
          id: 'menu.ine.comunidadautonoma',
          displayName: this.translate.instant('menu.ine.comunidadautonoma'),
          outlet: 'comunidadautonoma',
          module: 'ine',
          permission: 'all_ent_ine_comunidadAutonoma',
          hasPermission: this.authorizationService.hasPermission('all_ent_ine_comunidadAutonoma'),
          route: '/ine/comunidadautonoma/comunidadautonomalist'
        },
        {
          id: 'menu.ine.diputacion',
          displayName: this.translate.instant('menu.ine.diputacion'),
          outlet: 'diputacion',
          module: 'ine',
          permission: 'all_ent_ine_diputacion',
          hasPermission: this.authorizationService.hasPermission('all_ent_ine_diputacion'),
          route: '/ine/diputacion/diputacionlist'
        },
        {
          id: 'menu.ine.ayuntamiento',
          displayName: this.translate.instant('menu.ine.ayuntamiento'),
          outlet: 'ayuntamiento',
          module: 'ine',
          permission: 'all_ent_ine_ayuntamiento',
          hasPermission: this.authorizationService.hasPermission('all_ent_ine_ayuntamiento'),
          route: '/ine/ayuntamiento/ayuntamientolist'
        },
        {
          id: 'menu.ine.via',
          displayName: this.translate.instant('menu.ine.via'),
          outlet: 'via',
          module: 'ine',
          permission: 'all_ent_ine_via',
          hasPermission: this.authorizationService.hasPermission('all_ent_ine_via'),
          route: '/ine/via/vialist'
        },
        {
          id: 'menu.ine.vivienda',
          displayName: this.translate.instant('menu.ine.vivienda'),
          outlet: 'vivienda',
          module: 'ine',
          permission: 'all_ent_ine_vivienda',
          hasPermission: this.authorizationService.hasPermission('all_ent_ine_vivienda'),
          route: '/ine/vivienda/viviendalist'
        },
        {
          id: 'menu.ine.hogar',
          displayName: this.translate.instant('menu.ine.hogar'),
          outlet: 'hogar',
          module: 'ine',
          permission: 'all_ent_ine_hogar',
          hasPermission: this.authorizationService.hasPermission('all_ent_ine_hogar'),
          route: '/ine/hogar/hogarlist'
        },
        {
          id: 'menu.dgc.dgcprovincia',
          displayName: this.translate.instant('menu.dgc.dgcprovincia'),
          outlet: 'dgcprovincia',
          module: 'dgc',
          permission: 'all_ent_dgc_dgcprovincia',
          hasPermission: this.authorizationService.hasPermission('all_ent_dgc_dgcprovincia'),
          route: '/dgc/dgcprovincia/dgcprovincialist'
        },
        {
          id: 'menu.dgc.dgcmunicipio',
          displayName: this.translate.instant('menu.dgc.dgcmunicipio'),
          outlet: 'dgcmunicipio',
          module: 'dgc',
          permission: 'all_ent_dgc_dgcmunicipio',
          hasPermission: this.authorizationService.hasPermission('all_ent_dgc_dgcmunicipio'),
          route: '/dgc/dgcmunicipio/dgcmunicipiolist'
        },
        {
          id: 'menu.dgc.dgcvia',
          displayName: this.translate.instant('menu.dgc.dgcvia'),
          outlet: 'dgcvia',
          module: 'dgc',
          permission: 'all_ent_dgc_dgcvia',
          hasPermission: this.authorizationService.hasPermission('all_ent_dgc_dgcvia'),
          route: '/dgc/dgcvia/dgcvialist'
        },
        {
          id: 'menu.utilidades.notificacion',
          displayName: this.translate.instant('menu.utilidades.notificacion'),
          outlet: 'notificacion',
          module: 'utilidades',
          permission: 'all_ent_utilidades_notificacion',
          hasPermission: this.authorizationService.hasPermission('all_ent_utilidades_notificacion'),
          route: '/utilidades/notificacion/notificacionlist'
        },
        {
          id: 'menu.utilidades.dashboard',
          displayName: this.translate.instant('menu.utilidades.dashboard'),
          outlet: 'dashboard',
          module: 'utilidades',
          permission: 'all_ent_utilidades_dashboard',
          hasPermission: this.authorizationService.hasPermission('all_ent_utilidades_dashboard'),
          route: '/utilidades/dashboard/dashboardlist'
        },
        {
          id: 'menu.utilidades.panel',
          displayName: this.translate.instant('menu.utilidades.panel'),
          outlet: 'panel',
          module: 'utilidades',
          permission: 'all_ent_utilidades_panel',
          hasPermission: this.authorizationService.hasPermission('all_ent_utilidades_panel'),
          route: '/utilidades/panel/panellist'
        },
        {
          id: 'menu.tareas.a1',
          displayName: this.translate.instant('menu.tareas.a1'),
          outlet: 'a1',
          module: 'tareas',
          permission: 'all_ent_tareas_a1',
          hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a1'),
          route: '/tareas/a1/a1list'
        },
        {
          id: 'menu.tareas.a2',
          displayName: this.translate.instant('menu.tareas.a2'),
          outlet: 'a2',
          module: 'tareas',
          permission: 'all_ent_tareas_a2',
          hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a2'),
          route: '/tareas/a2/a2list'
        },
        {
          id: 'menu.tareas.a3',
          displayName: this.translate.instant('menu.tareas.a3'),
          outlet: 'a3',
          module: 'tareas',
          permission: 'all_ent_tareas_a3',
          hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a3'),
          route: '/tareas/a3/a3list'
        },
        {
          id: 'menu.tareas.a4',
          displayName: this.translate.instant('menu.tareas.a4'),
          outlet: 'a4',
          module: 'tareas',
          permission: 'all_ent_tareas_a4',
          hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a4'),
          route: '/tareas/a4/a4list'
        },
        {
          id: 'menu.tareas.a5',
          displayName: this.translate.instant('menu.tareas.a5'),
          outlet: 'a5',
          module: 'tareas',
          permission: 'all_ent_tareas_a5',
          hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a5'),
          route: '/tareas/a5/a5list'
        },
        {
          id: 'menu.tareas.a6',
          displayName: this.translate.instant('menu.tareas.a6'),
          outlet: 'a6',
          module: 'tareas',
          permission: 'all_ent_tareas_a6',
          hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a6'),
          route: '/tareas/a6/a6list'
        },
        {
          id: 'menu.tareas.b',
          displayName: this.translate.instant('menu.tareas.b'),
          outlet: 'b',
          module: 'tareas',
          permission: 'all_ent_tareas_b',
          hasPermission: this.authorizationService.hasPermission('all_ent_tareas_b'),
          route: '/tareas/b/blist'
        },
        {
          id: 'menu.tareas.c',
          displayName: this.translate.instant('menu.tareas.c'),
          outlet: 'c',
          module: 'tareas',
          permission: 'all_ent_tareas_c',
          hasPermission: this.authorizationService.hasPermission('all_ent_tareas_c'),
          route: '/tareas/c/clist'
        },
        {
          id: 'menu.informes.tipoinforme',
          displayName: this.translate.instant('menu.informes.tipoinforme'),
          outlet: 'tipoinforme',
          module: 'informes',
          permission: 'all_ent_informes_tipoInforme',
          hasPermission: this.authorizationService.hasPermission('all_ent_informes_tipoInforme'),
          route: '/informes/tipoinforme/tipoinformelist'
        },
    ];
    this.setLogo();
    this.tabService.addTabOptions(this.tabs);
  }    
  
  /**
   * Emisión de evento de cambio de menu de vertical a horizontal
   */
  changeMenu() {
    this.changemenu.emit();
  }
  
  /**
   * Getter logoCabeceraMenu
   * @return {string}
   */
  public get logoCabeceraMenu(): string {
    return this._logoCabeceraMenu;
  }

  /**
   * Setter logoCabeceraMenu
   * @param {string} value
   */
  public set logoCabeceraMenu(value: string) {
    this._logoCabeceraMenu = value;
  }
  
  setLogo() {
    this.logoCabeceraMenu = environment.logos.logo_cabecera_menu;
  }  
  
  
  /**
   * Muestra los items del menu
   * @returns void
   */
  showItems(): void {
    this.navItems = [
      {
        id: 'menu.seguridad',
        displayName: this.translate.instant('menu.seguridad.title'),
        module: 'seguridad',
        permission: 'all_mod_seguridad',
        hasPermission: this.authorizationService.hasPermission('all_mod_seguridad'),
        children: [
          {
            id: 'menu.seguridad.user',
            displayName: this.translate.instant('menu.seguridad.user'),
            module: 'seguridad',
            outlet: 'user',
            permission: 'all_ent_seguridad_user',
            hasPermission: this.authorizationService.hasPermission('all_ent_seguridad_user'),
            route: '/seguridad/user/userlist'
          },
          {
            id: 'menu.seguridad.rol',
            displayName: this.translate.instant('menu.seguridad.rol'),
            module: 'seguridad',
            outlet: 'rol',
            permission: 'all_ent_seguridad_rol',
            hasPermission: this.authorizationService.hasPermission('all_ent_seguridad_rol'),
            route: '/seguridad/rol/rollist'
          },
          {
            id: 'menu.seguridad.grupopermiso',
            displayName: this.translate.instant('menu.seguridad.grupopermiso'),
            module: 'seguridad',
            outlet: 'grupopermiso',
            permission: 'all_ent_seguridad_grupoPermiso',
            hasPermission: this.authorizationService.hasPermission('all_ent_seguridad_grupoPermiso'),
            route: '/seguridad/grupopermiso/grupopermisolist'
          },
        ]        
       },
      {
        id: 'menu.ine',
        displayName: this.translate.instant('menu.ine.title'),
        module: 'ine',
        permission: 'all_mod_ine',
        hasPermission: this.authorizationService.hasPermission('all_mod_ine'),
        children: [
          {
            id: 'menu.ine.comunidadautonoma',
            displayName: this.translate.instant('menu.ine.comunidadautonoma'),
            module: 'ine',
            outlet: 'comunidadautonoma',
            permission: 'all_ent_ine_comunidadAutonoma',
            hasPermission: this.authorizationService.hasPermission('all_ent_ine_comunidadAutonoma'),
            route: '/ine/comunidadautonoma/comunidadautonomalist'
          },
          {
            id: 'menu.ine.diputacion',
            displayName: this.translate.instant('menu.ine.diputacion'),
            module: 'ine',
            outlet: 'diputacion',
            permission: 'all_ent_ine_diputacion',
            hasPermission: this.authorizationService.hasPermission('all_ent_ine_diputacion'),
            route: '/ine/diputacion/diputacionlist'
          },
          {
            id: 'menu.ine.ayuntamiento',
            displayName: this.translate.instant('menu.ine.ayuntamiento'),
            module: 'ine',
            outlet: 'ayuntamiento',
            permission: 'all_ent_ine_ayuntamiento',
            hasPermission: this.authorizationService.hasPermission('all_ent_ine_ayuntamiento'),
            route: '/ine/ayuntamiento/ayuntamientolist'
          },
          {
            id: 'menu.ine.via',
            displayName: this.translate.instant('menu.ine.via'),
            module: 'ine',
            outlet: 'via',
            permission: 'all_ent_ine_via',
            hasPermission: this.authorizationService.hasPermission('all_ent_ine_via'),
            route: '/ine/via/vialist'
          },
          {
            id: 'menu.ine.vivienda',
            displayName: this.translate.instant('menu.ine.vivienda'),
            module: 'ine',
            outlet: 'vivienda',
            permission: 'all_ent_ine_vivienda',
            hasPermission: this.authorizationService.hasPermission('all_ent_ine_vivienda'),
            route: '/ine/vivienda/viviendalist'
          },
          {
            id: 'menu.ine.hogar',
            displayName: this.translate.instant('menu.ine.hogar'),
            module: 'ine',
            outlet: 'hogar',
            permission: 'all_ent_ine_hogar',
            hasPermission: this.authorizationService.hasPermission('all_ent_ine_hogar'),
            route: '/ine/hogar/hogarlist'
          },
        ]        
       },
      {
        id: 'menu.dgc',
        displayName: this.translate.instant('menu.dgc.title'),
        module: 'dgc',
        permission: 'all_mod_dgc',
        hasPermission: this.authorizationService.hasPermission('all_mod_dgc'),
        children: [
          {
            id: 'menu.dgc.dgcprovincia',
            displayName: this.translate.instant('menu.dgc.dgcprovincia'),
            module: 'dgc',
            outlet: 'dgcprovincia',
            permission: 'all_ent_dgc_dgcprovincia',
            hasPermission: this.authorizationService.hasPermission('all_ent_dgc_dgcprovincia'),
            route: '/dgc/dgcprovincia/dgcprovincialist'
          },
          {
            id: 'menu.dgc.dgcmunicipio',
            displayName: this.translate.instant('menu.dgc.dgcmunicipio'),
            module: 'dgc',
            outlet: 'dgcmunicipio',
            permission: 'all_ent_dgc_dgcmunicipio',
            hasPermission: this.authorizationService.hasPermission('all_ent_dgc_dgcmunicipio'),
            route: '/dgc/dgcmunicipio/dgcmunicipiolist'
          },
          {
            id: 'menu.dgc.dgcvia',
            displayName: this.translate.instant('menu.dgc.dgcvia'),
            module: 'dgc',
            outlet: 'dgcvia',
            permission: 'all_ent_dgc_dgcvia',
            hasPermission: this.authorizationService.hasPermission('all_ent_dgc_dgcvia'),
            route: '/dgc/dgcvia/dgcvialist'
          },
        ]        
       },
      {
        id: 'menu.utilidades',
        displayName: this.translate.instant('menu.utilidades.title'),
        module: 'utilidades',
        permission: 'all_mod_utilidades',
        hasPermission: this.authorizationService.hasPermission('all_mod_utilidades'),
        children: [
          {
            id: 'menu.utilidades.notificacion',
            displayName: this.translate.instant('menu.utilidades.notificacion'),
            module: 'utilidades',
            outlet: 'notificacion',
            permission: 'all_ent_utilidades_notificacion',
            hasPermission: this.authorizationService.hasPermission('all_ent_utilidades_notificacion'),
            route: '/utilidades/notificacion/notificacionlist'
          },
          {
            id: 'menu.utilidades.dashboard',
            displayName: this.translate.instant('menu.utilidades.dashboard'),
            module: 'utilidades',
            outlet: 'dashboard',
            permission: 'all_ent_utilidades_dashboard',
            hasPermission: this.authorizationService.hasPermission('all_ent_utilidades_dashboard'),
            route: '/utilidades/dashboard/dashboardlist'
          },
          {
            id: 'menu.utilidades.panel',
            displayName: this.translate.instant('menu.utilidades.panel'),
            module: 'utilidades',
            outlet: 'panel',
            permission: 'all_ent_utilidades_panel',
            hasPermission: this.authorizationService.hasPermission('all_ent_utilidades_panel'),
            route: '/utilidades/panel/panellist'
          },
        ]        
       },
      {
        id: 'menu.tareas',
        displayName: this.translate.instant('menu.tareas.title'),
        module: 'tareas',
        permission: 'all_mod_tareas',
        hasPermission: this.authorizationService.hasPermission('all_mod_tareas'),
        children: [
          {
            id: 'menu.tareas.a1',
            displayName: this.translate.instant('menu.tareas.a1'),
            module: 'tareas',
            outlet: 'a1',
            permission: 'all_ent_tareas_a1',
            hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a1'),
            route: '/tareas/a1/a1list'
          },
          {
            id: 'menu.tareas.a2',
            displayName: this.translate.instant('menu.tareas.a2'),
            module: 'tareas',
            outlet: 'a2',
            permission: 'all_ent_tareas_a2',
            hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a2'),
            route: '/tareas/a2/a2list'
          },
          {
            id: 'menu.tareas.a3',
            displayName: this.translate.instant('menu.tareas.a3'),
            module: 'tareas',
            outlet: 'a3',
            permission: 'all_ent_tareas_a3',
            hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a3'),
            route: '/tareas/a3/a3list'
          },
          {
            id: 'menu.tareas.a4',
            displayName: this.translate.instant('menu.tareas.a4'),
            module: 'tareas',
            outlet: 'a4',
            permission: 'all_ent_tareas_a4',
            hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a4'),
            route: '/tareas/a4/a4list'
          },
          {
            id: 'menu.tareas.a5',
            displayName: this.translate.instant('menu.tareas.a5'),
            module: 'tareas',
            outlet: 'a5',
            permission: 'all_ent_tareas_a5',
            hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a5'),
            route: '/tareas/a5/a5list'
          },
          {
            id: 'menu.tareas.a6',
            displayName: this.translate.instant('menu.tareas.a6'),
            module: 'tareas',
            outlet: 'a6',
            permission: 'all_ent_tareas_a6',
            hasPermission: this.authorizationService.hasPermission('all_ent_tareas_a6'),
            route: '/tareas/a6/a6list'
          },
          {
            id: 'menu.tareas.b',
            displayName: this.translate.instant('menu.tareas.b'),
            module: 'tareas',
            outlet: 'b',
            permission: 'all_ent_tareas_b',
            hasPermission: this.authorizationService.hasPermission('all_ent_tareas_b'),
            route: '/tareas/b/blist'
          },
          {
            id: 'menu.tareas.c',
            displayName: this.translate.instant('menu.tareas.c'),
            module: 'tareas',
            outlet: 'c',
            permission: 'all_ent_tareas_c',
            hasPermission: this.authorizationService.hasPermission('all_ent_tareas_c'),
            route: '/tareas/c/clist'
          },
        ]        
       },
      {
        id: 'menu.informes',
        displayName: this.translate.instant('menu.informes.title'),
        module: 'informes',
        permission: 'all_mod_informes',
        hasPermission: this.authorizationService.hasPermission('all_mod_informes'),
        children: [
          {
            id: 'menu.informes.tipoinforme',
            displayName: this.translate.instant('menu.informes.tipoinforme'),
            module: 'informes',
            outlet: 'tipoinforme',
            permission: 'all_ent_informes_tipoInforme',
            hasPermission: this.authorizationService.hasPermission('all_ent_informes_tipoInforme'),
            route: '/informes/tipoinforme/tipoinformelist'
          },
        ]        
       },
    ];
  }
  
  /**
   * Chequea un permiso para el usuario
   * @param  {string} permission
   * @returns boolean
   */
  public hasPermission(permission: string): boolean {
    if (this.isauthorized == undefined) {
      this.isauthorized = this.authorizationService.hasPermission(permission);
    }
    return this.isauthorized;
  }

  /**
   * Cambia el lenguaje del interfaz
   * @param  {string} language
   */
  public async setLanguage(language: string) {
    await this.translate.use(language).toPromise();
    sessionStorage.setItem("language", language);
    this.showItems();
  }

  /**
   * Cambio de password
   * @returns void
   */
  changePassword(): void {
    const dialogRef = this.modalService.open(
      DialogPasswordComponent,
      MODAL_OPTIONS
    );
    let data = {
      password: this.password,
      newpassword: this.newpassword,
      passwordconfirm: this.confirmpassword,
    };
    dialogRef.componentInstance.data = data;
  }

  /**
   * Cierre de sesión
   * @returns void
   */
  closeSession(): void {
    this.router
      .navigate([{ outlets: { [this.utilService.activeOutlet]: null } }])
      .then(() => {
        this.authenticationService.logout();
        this.router.navigate(["/login"]);
        this.utilService.activeOutlet = "";
      });
  }

  /**
   * Getter navItems
   * @return {NavItem[]}
   */
  public get navItems(): NavItem[] {
    return this._navItems;
  }

  /**
   * Getter tabs
   * @return {NavItem[]}
   */
  public get tabs(): NavItem[] {
    return this._tabs;
  }

  /**
   * Getter password
   * @return {string}
   */
  public get password(): string {
    return this._password;
  }

  /**
   * Getter newpassword
   * @return {string}
   */
  public get newpassword(): string {
    return this._newpassword;
  }

  /**
   * Getter confirmpassword
   * @return {string}
   */
  public get confirmpassword(): string {
    return this._confirmpassword;
  }

  /**
   * Getter currentUser
   * @return {boolean}
   */
  public get currentUser(): boolean {
    return this._currentUser;
  }

  /**
   * Getter isauthorized
   * @return {boolean}
   */
  public get isauthorized(): boolean {
    return this._isauthorized;
  }

  /**
   * Setter navItems
   * @param {NavItem[]} value
   */
  public set navItems(value: NavItem[]) {
    this._navItems = value;
  }

  /**
   * Setter tabs
   * @param {NavItem[]} value
   */
  public set tabs(value: NavItem[]) {
    this._tabs = value;
  }

  /**
   * Setter password
   * @param {string} value
   */
  public set password(value: string) {
    this._password = value;
  }

  /**
   * Setter newpassword
   * @param {string} value
   */
  public set newpassword(value: string) {
    this._newpassword = value;
  }

  /**
   * Setter confirmpassword
   * @param {string} value
   */
  public set confirmpassword(value: string) {
    this._confirmpassword = value;
  }

  /**
   * Setter currentUser
   * @param {boolean} value
   */
  public set currentUser(value: boolean) {
    this._currentUser = value;
  }

  /**
   * Setter isauthorized
   * @param {boolean} value
   */
  public set isauthorized(value: boolean) {
    this._isauthorized = value;
  }
}

