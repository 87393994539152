import { Injectable,OnInit } from '@angular/core';
import { GeneratedDocfileService } from 'src/app/generated/comun/docfile/service/generated-docfile.service';

/**
 * Servicio Docfile
 * @extends {GeneratedDocfileService}
 * @implements {OnInit}
 */
@Injectable({
    providedIn: 'root',
})
export class DocfileService extends GeneratedDocfileService implements OnInit{
  override ngOnInit() {
    super.ngOnInit();
  }
}
