import { AbstractControl } from "@angular/forms";

/**
 * Validador de contraseña
 */
export class PasswordValidator {
  /**
   * Comprueba si las passwords coinciden.
   *
   * @param  {AbstractControl} control
   */
  static MatchPassword(control: AbstractControl) {
    if (control) {
      let password = control.get("newpassword")?.value;
      let confirmPassword = control.get("confirmPassword")?.value;
      if (password != confirmPassword) {
        control.get("confirmPassword")?.setErrors({ matchpassword: true });
      } else {
        return null;
      }
    }
    return null;
  }

  /**
   * Comprueba si la password nueva coincide con la antigua.
   *
   * @param  {AbstractControl} control
   */
  static MatchOldPassword(control: AbstractControl) {
    if (control) {
      let password = control.get("password")?.value;
      let newPassword = control.get("newpassword")?.value;
      if (password == newPassword) {
        control.get("newpassword")?.setErrors({ matcholdpassword: true });
      } else {
        return null;
      }
    }
    return null;
  }
}
