import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Token } from "src/app/jnum-core/model/token";
import { environment } from "src/environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }),
};
const roleUrl = environment.serviceUrl + "/jwt/hasRole";
/**
 * Servicio de autorización
 */
@Injectable()
export class AuthorizationService {
  private _token!: Token;
  private _submodulo: string = "";

  constructor(protected http: HttpClient) {}

  /** Getter httpHeaders.
   *
   * @returns HttpHeaders
   */
  getHttpHeaders(): HttpHeaders {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser") ?? "");
    this.token = currentUser && currentUser.token;
    if (this.token) {
      return new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + this.token.jwtToken,
      });
    }
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });
  }

  /**
   * Consulta en el back si un usuario tiene permiso para una operación.
   * @param  {string} permission
   * @returns Observable
   */
  hasRemotePermission(permission: string): Observable<boolean> {
    return this.http.post<any>(roleUrl, permission, {
      headers: this.getHttpHeaders(),
    });
  }
  /**
   * Indica si un usuario tiene permiso para una operación.
   *
   * @param  {string} permission
   * @returns boolean
   */
  hasPermission(permission: string): boolean {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser") ?? "");
    if (currentUser && currentUser.token) {
      let authorities = currentUser.token.authorities;
      if (
        authorities.includes("all_https") ||
        authorities.includes("all_http")
      ) {
        return true;
      }
      if (permission.startsWith("all_mod")) {
        let entrole = permission.substring("all_mod".length + 1);
        return (
          authorities.includes(permission) ||
          authorities.includes("all_ent_" + entrole) ||
          authorities.includes("add_ent_" + entrole) ||
          authorities.includes("edit_ent_" + entrole) ||
          authorities.includes("delete_ent_" + entrole) ||
          authorities.includes("view_ent_" + entrole)
        );
      }
      if (permission.startsWith("all_ent")) {
        let entrole = permission.substring("all_ent".length + 1);
        let splitRole = permission.split("_");
        let module = splitRole[2];
        let allMod = "all_mod_" + module;
        return (
          authorities.includes(permission) ||
          authorities.includes(allMod) ||
          authorities.includes("add_ent_" + entrole) ||
          authorities.includes("edit_ent_" + entrole) ||
          authorities.includes("delete_ent_" + entrole) ||
          authorities.includes("view_ent_" + entrole)
        );
      }

      if (
        permission.startsWith("add_") ||
        permission.startsWith("edit_") ||
        permission.startsWith("add_") ||
        permission.startsWith("delete_") ||
        permission.startsWith("view_")
      ) {
        let splitRole = permission.split("_");
        let module = splitRole[2];
        let entidad = splitRole[3];
        let allMod = "all_mod_" + module;
        let allEnt = "all_ent_" + module + "_" + entidad;
        return (
          authorities.includes(permission) ||
          authorities.includes(allMod) ||
          authorities.includes(allEnt)
        );
      }
      if (
        permission.startsWith("op_") &&
        !(
          permission === "op_change_password" ||
          permission === "op_change_language"
        )
      ) {
        let splitRole = permission.split("_");
        let module = splitRole[1];
        let entidad = splitRole[2];
        let allMod = "all_mod_" + module;
        let allEnt = "all_ent_" + module + "_" + entidad;
        return (
          authorities.includes(permission) ||
          authorities.includes(allMod) ||
          authorities.includes(allEnt)
        );
      }
      return authorities.includes(permission);
    }
    return false;
  }

  /**
   * Getter token
   * @return {Token}
   */
  public get token(): Token {
    return this._token;
  }

  /**
   * Setter token
   * @param {Token} value
   */
  public set token(value: Token) {
    this._token = value;
  }

  /**
   * Getter submodulo
   * @return {string}
   */
  public get submodulo(): string {
    return this._submodulo;
  }

  /**
   * Setter submodulo
   * @param {string} value
   */
  public set submodulo(value: string) {
    this._submodulo = value;
  }
}
