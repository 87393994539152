import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs/internal/Subscription";
import { NavItem } from "src/app/jnum-core/navbar/nav-item";
import { JnumStateStrategy } from "src/app/jnum-core/routing/jnumstatestrategy.component";
import { AuthenticationService } from "src/app/jnum-core/services/authentication.service";
import { IconService } from "src/app/jnum-core/services/icons.service";
import { NavigationService } from "src/app/jnum-core/services/navigation.service";
import { TabService } from "src/app/jnum-core/services/tab.service";
import { ToasterService } from "src/app/jnum-core/services/toaster.service";
import { UtilService } from "src/app/jnum-core/services/util.service";

@Component({
  selector: "app-tabs-component",
  templateUrl: "./tabs.component.html",
})
export class TabsComponent {
  tabs: NavItem[] = [];
  activeTabOutlet: string = "primary";
  openTabEventSubscription!: Subscription;
  jnumstatestrategy: JnumStateStrategy;
  static lastComp: TabsComponent;

  /**
   * @param  {ToasterService} toasterService
   * @param  {NavigationService} navigationService
   * @param  {TranslateService} translate
   * @param  {AuthenticationService} authenticationService
   * @param  {Router} router
   * @param  {IconService} iconService
   * @param  {TabService} tabService
   * @param  {UtilService} utilService
   * @param  {NgbModal} modalService
   */
  constructor(
    protected toasterService: ToasterService,
    protected navigationService: NavigationService,
    protected translate: TranslateService,
    protected authenticationService: AuthenticationService,
    protected router: Router,
    protected iconService: IconService,
    protected tabService: TabService,
    protected utilService: UtilService,
    protected modalService: NgbModal
  ) {
    this.jnumstatestrategy = router.routeReuseStrategy as JnumStateStrategy;
  }

  /**
   * Inicializa los tabs y se subscribe a la apertura de un nuevo tab
   * @returns void
   */
  ngOnInit(): void {
    this.tabs = this.tabService.tabs;
    this.openTabEventSubscription = this.tabService
      .getOpenTabEvent()
      .subscribe((navitem) => {
        if (navitem) {
          this.router
            .navigate([{ outlets: { [this.activeTabOutlet]: null } }])
            .then(() => {
              this.activeTabOutlet = navitem.outlet ?? "";
              this.utilService.activeOutlet = this.activeTabOutlet;
              this.jnumstatestrategy.setActiveOutlet(this.activeTabOutlet);
              this.navigationService.setHistory(navitem.outlet);
              this.router.navigateByUrl(navitem.route ?? "");
            });
        }
      });
  }

  /**
   * Cancela la subscripciones y limpia todas las pestañas
   * @returns void
   */
  ngOnDestroy(): void {
    if (this.openTabEventSubscription) {
      this.openTabEventSubscription.unsubscribe();
    }
    this.router
      .navigate([{ outlets: { [this.activeTabOutlet]: null } }])
      .then(() => {
        this.tabService.clearTabs();
        this.jnumstatestrategy.removeAllRoute();
      });
  }

  /**
   * Gestiona el cierre de una pestaña
   * @param  {number} index
   * @param  {Event} event
   */
  closeTab(index: number, event: Event) {
    this.jnumstatestrategy.removeOutlethandlers(this.activeTabOutlet);
    this.activeTabOutlet =
      this.tabService.deleteTab(index, this.activeTabOutlet) ?? "";
    this.utilService.activeOutlet = this.activeTabOutlet;
    this.jnumstatestrategy.setActiveOutlet(this.activeTabOutlet);
    event.preventDefault();
  }

  /**
   * Gestiona el cambio de pestaña
   * @param  {NgbNavChangeEvent} event
   */
  onTabChange(event: NgbNavChangeEvent) {
    this.router.navigate([{ outlets: { [event.activeId]: null } }]).then(() => {
      this.activeTabOutlet = event.nextId;
      this.utilService.activeOutlet = this.activeTabOutlet;
      this.jnumstatestrategy.setActiveOutlet(this.activeTabOutlet);
      this.router.navigateByUrl(
        this.navigationService.getLastRoute(this.activeTabOutlet)
      );
      this.navigationService.setHistory(this.activeTabOutlet);
    });
  }
}
