import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PasswordValidator } from "src/app/jnum-core/validators/password-validator";
import { ValidationFormService } from "src/app/jnum-core/services/validation-form.service";
import { UtilService } from "../services/util.service";
import { CommonModule } from "@angular/common";

import { UserService } from "src/app/customized/seguridad/user/service/user.service";
import { User } from "../model/user";
import { JnumCoreModule } from "../jnum-core.module";
import { TranslateModule } from "@ngx-translate/core";

/**
 * Interfaz para el cambio de contraseña
 */
export interface PasswordData {
  password: string;
  newpassword: string;
  passwordconfirm: string;
}

/**
 * Componente para el cambio de contraseña
 */
@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule, JnumCoreModule, ReactiveFormsModule],
  selector: "dialog-password-dialog",
  templateUrl: "./dialog-password.html",
})
export class DialogPasswordComponent implements OnInit {
  private _passwordForm!: FormGroup;
  private _data!: PasswordData;

  constructor(
    protected fb: FormBuilder,
    protected validationFormService: ValidationFormService,
    protected utilService: UtilService,
    protected userService: UserService,
    protected activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.createPasswordForm();
  }

  /**
   * Crea el formulario de cambio de contraseña
   */
  protected createPasswordForm() {
    this.passwordForm = this.fb.group(
      {
        password: new FormControl("", [Validators.required]),
        newpassword: new FormControl("", [Validators.required]),
        confirmPassword: new FormControl("", [Validators.required]),
      },
      {
        validator: [
          PasswordValidator.MatchPassword,
          PasswordValidator.MatchOldPassword,
        ],
      }
    );
  }

  /**
   * Getter password
   */
  get password() {
    return this.passwordForm.get("password");
  }

  /**
   * Getter newpassword
   */
  get newpassword() {
    return this.passwordForm.get("newpassword");
  }

  /**
   * Getter confirmPassword
   */
  get confirmPassword() {
    return this.passwordForm.get("confirmPassword");
  }

  /**
   * Cierre de modal
   * @returns void
   */
  closeModal(): void {
    this.activeModal.close();
  }
  /**
   * Submit del formulario
   */
  onSubmit() {
    if (this.passwordForm.valid) {
      this.userService
        .changePassword(this.passwordForm?.get("newpassword")?.value)
        .subscribe((_) => {
          this.activeModal.close();
          this.utilService.showInfo("toaster.changepasswordok");
        });
    } else {
      this.validationFormService.validateAllFormFields(this.passwordForm);
    }
  }

  /**
   * Getter passwordForm
   * @return {FormGroup}
   */
  public get passwordForm(): FormGroup {
    return this._passwordForm;
  }

  /**
   * Getter data
   * @return {PasswordData}
   */
  public get data(): PasswordData {
    return this._data;
  }

  /**
   * Setter passwordForm
   * @param {FormGroup} value
   */
  public set passwordForm(value: FormGroup) {
    this._passwordForm = value;
  }

  /**
   * Setter data
   * @param {PasswordData} value
   */
  public set data(value: PasswordData) {
    this._data = value;
  }
}
