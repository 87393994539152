import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NavItem } from "src/app/jnum-core/navbar/nav-item";
import { Observable, Subject } from "rxjs";
import { NavigationService } from "./navigation.service";

/**
 * Servicio de gestión de pestañas.
 */
@Injectable({
  providedIn: "root",
})
export class TabService {
  private _openTabEvent: Subject<NavItem> = new Subject<NavItem>();
  private _tabs: NavItem[] = [];
  private _tabOptions: NavItem[] = [];

  constructor(
    protected router: Router,
    protected navigationService: NavigationService
  ) {
    this.openTabEvent = new Subject();
    this.tabs = [];
    this.tabOptions = [];
  }

  /**
   * @returns Observable
   */
  getOpenTabEvent(): Observable<NavItem> {
    return this.openTabEvent.asObservable();
  }

  /**
   * @param  {NavItem} navitem
   * @returns void
   */
  setOpenTabEvent(navitem: NavItem): void {
    this.openTabEvent.next(navitem);
  }

  /**
   * Añade tabOptions
   * @param  {NavItem[]} tabsoptions
   */
  addTabOptions(tabsoptions: NavItem[]) {
    this.tabOptions = tabsoptions;
  }

  /**
   * Notifica la apertura de una nueva pestaña.
   * @param  {NavItem} navitem
   */
  notifyOpenTab(navitem: NavItem) {
    this.setOpenTabEvent(navitem);
  }

  /**
   * Añade una nueva pestaña.
   *
   * @param  {NavItem} navitem
   */
  addTab(navitem: NavItem) {
    const tab = this.getTabOptionByUrl(navitem.route ?? "");
    if (tab && !this.tabs.find((x) => x.route == tab.route)) {
      this.tabs.push(tab);
    }
  }

  /**
   * Devuelve la url de una pestaña.
   *
   * @param  {string} url
   * @returns NavItem
   */
  getTabOptionByUrl(url: string): NavItem | undefined {
    return this.tabOptions.find((tab) => tab.route === url);
  }

  /**
   * Elimina una pestaña y comprueba si es la pestaña activa.
   * En caso de que así sea establece como activa otra pestaña.
   *
   * @param  {number} index
   * @param  {string} activetaboutlet
   * @returns string
   */
  deleteTab(index: number, activetaboutlet: string): string | undefined {
    let activeTab: NavItem | undefined = this.tabs.find(
      (x) => x.outlet == activetaboutlet
    );
    let tabToDelete = this.getTabByIndex(index);
    let newActiveTab!: NavItem;
    if (activeTab && tabToDelete) {
      const outletToDelete: string = tabToDelete.outlet ?? "";
      if (tabToDelete.outlet == activeTab.outlet) {
        if (index > 0) {
          newActiveTab = this.getTabByIndex(index - 1);
        } else if (this.getTabs().length > 1) {
          newActiveTab = this.getTabByIndex(index + 1);
        }
      } else {
        newActiveTab = activeTab;
      }

      this.tabs.splice(index, 1);
      if (tabToDelete.outlet == activeTab.outlet && this.tabs.length > 0) {
        this.router
          .navigate([{ outlets: { [outletToDelete]: null } }])
          .then(() => {
            this.router.navigateByUrl(
              this.navigationService.getLastRoute(newActiveTab.outlet)
            );
            this.navigationService.setHistory(newActiveTab.outlet);
          });
      } else if (this.tabs.length == 0) {
        this.clearTabs();
        this.router.navigateByUrl("/");
        return "primary";
      }
      this.navigationService.deleteHistory(tabToDelete.outlet);
      return newActiveTab.outlet;
    }
    return "";
  }

  /**
   * Devuelve una pestaña por su índice.
   *
   * @param  {number} index
   * @returns NavItem
   */
  getTabByIndex(index: number): NavItem {
    return this.tabs[index];
  }

  /**
   * Devuelve la lista de pestañas.
   *
   * @returns NavItem
   */
  getTabs(): NavItem[] {
    return this.tabs;
  }

  /**
   * Limpia todas las pestañas del listado.
   */
  clearTabs() {
    this.tabs.splice(0, this.tabs.length);
    this.navigationService.deleteAllHistory();
  }

  /**
   * Devuelve el número de pestañas.
   *
   * @returns number
   */
  getNumTabs(): number {
    return this.tabs.length + 1;
  }

  /**
   * Getter openTabEvent
   * @return {Subject<NavItem>}
   */
  public get openTabEvent(): Subject<NavItem> {
    return this._openTabEvent;
  }

  /**
   * Getter tabs
   * @return {NavItem[]}
   */
  public get tabs(): NavItem[] {
    return this._tabs;
  }

  /**
   * Getter tabOptions
   * @return {NavItem[]}
   */
  public get tabOptions(): NavItem[] {
    return this._tabOptions;
  }

  /**
   * Setter openTabEvent
   * @param {Subject<NavItem>} value
   */
  public set openTabEvent(value: Subject<NavItem>) {
    this._openTabEvent = value;
  }

  /**
   * Setter tabs
   * @param {NavItem[]} value
   */
  public set tabs(value: NavItem[]) {
    this._tabs = value;
  }

  /**
   * Setter tabOptions
   * @param {NavItem[]} value
   */
  public set tabOptions(value: NavItem[]) {
    this._tabOptions = value;
  }
}
