<nav class="navbar navbar-expand-lg jnum-primary">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img class="d-inline-block align-top logocabeceramenu" [src]="logoCabeceraMenu" alt="{{'login.logo.cabecera.menu.alt'|translate}}">
    </a> 
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0"> 
        <li *ngFor="let item of navItems">
          <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: item,parentId:item.displayName+'Dropdown',ismenu:true }"></ng-container>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item dropdown" ngbDropdown appHasPermission permission="all_mod_account">
          <a class="nav-link dropdown-toggle" id="accountDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
            ngbDropdownToggle aria-expanded="false"><span>{{'menu.account'|translate}}</span><strong class="caret"></strong></a>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="accountDropdown" ngbDropdownMenu placement="bottom-left">
            <a class="dropdown-item" (click)="changePassword()" ngbDropdownItem><span>{{'menu.password'|translate}}</span></a>
            <a class="dropdown-item" (click)="closeSession()" ngbDropdownItem><span>{{'menu.logout'|translate}}</span></a>
          </div>
        </li>
      </ul>  
    </div>
  </div>
</nav>
 
<ng-template #recursiveListTmpl let-item="item" let-parentId="parentId" let-ismenu="ismenu">
  <a *ngIf="(!item.children || item.children.length === 0) && ismenu" [ngClass]="!item.hasPermission?'nav-link menu-disabled':'nav-link'" tabindex="0" id="{{item.displayName}}Dropdown" role="button">
    <span [ngClass]="{'menu-disabled':!item.hasPermission}">{{item.displayName}}</span>
  </a>
  <a *ngIf="!item.hasPermission && (!item.children || item.children.length === 0) && !ismenu" class="dropdown-item" ngbDropdownItem id="{{item.id}}">
      <span class="menu-disabled">{{item.displayName}}</span>
  </a>
  <a *ngIf="item.hasPermission && (!item.children || item.children.length === 0) && !ismenu" class="dropdown-item" ngbDropdownItem (click)="openTab(item)" id="{{item.id}}">
      <span>{{item.displayName}}</span>
  </a>
  <li class="nav-item" ngbDropdown *ngIf="ismenu" appHasPermission permission="all_mod_{{item.module}}">
    <a [ngClass]="!item.hasPermission?'nav-link menu-disabled':'nav-link'" tabindex="0" ngbDropdownToggle id="{{item.displayName}}Dropdown" role="button">
      <span [ngClass]="{'menu-disabled':!item.hasPermission}">{{item.displayName}}</span>
    </a>   
    <div [ngClass]="ismenu?'dropdown-menu':'dropdown-submenu'" [attr.aria-labelledby]="parentId" ngbDropdownMenu>
      <span *ngFor="let child of item.children">
        <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: child,parentId:child.displayName+'Dropdown',ismenu:false }"></ng-container>
      </span>
    </div>   
  </li>

  <li class="nav-item" ngbDropdown *ngIf="item.children && item.children.length > 0 && !ismenu" appHasPermission permission="{{item.permission}}" class="dropdown-submenu">
    <a [ngClass]="!item.hasPermission?'dropdown-item menu-disabled':'dropdown-item'" tabindex="0" ngbDropdownToggle id="{{item.displayName}}Dropdown" role="button">
      <span [ngClass]="{'menu-disabled':!item.hasPermission}">{{item.displayName}}</span>
    </a>
    <div [ngClass]="ismenu?'dropdown-menu':'dropdown-submenu'" [attr.aria-labelledby]="parentId" ngbDropdownMenu>
      <span *ngFor="let child of item.children">
        <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: child,parentId:child.displayName+'Dropdown',ismenu:false }"></ng-container>
      </span>
    </div>   
    </li>    
</ng-template>