import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/jnum-core/services/authentication.service";
import { ValidationFormService } from "src/app/jnum-core/services/validation-form.service";
import { environment } from "src/environments/environment";

/**
 * Componente que gestiona el login del usuario
 */
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  private _loginForm!: FormGroup;
  private _logoLogin: string = "assets/images/jnum.png";
  private _logoLoginLateral: string = "assets/images/jnum2.png";
  private _textTitle: string = "";
  private _textSubTitle: string = "";

  constructor(
    protected fb: FormBuilder,
    protected authenticationService: AuthenticationService,
    protected router: Router,
    protected translate: TranslateService,
    protected validationFormService: ValidationFormService
  ) {}

  /**
   * Getter loginForm
   * @return {FormGroup}
   */
  public get loginForm(): FormGroup {
    return this._loginForm;
  }

  /**
   * Setter loginForm
   * @param {FormGroup} value
   */
  public set loginForm(value: FormGroup) {
    this._loginForm = value;
  }

  /**
   * Getter logoLogin
   * @return {string}
   */
  public get logoLogin(): string {
    return this._logoLogin;
  }

  /**
   * Setter logoLogin
   * @param {string} value
   */
  public set logoLogin(value: string) {
    this._logoLogin = value;
  }

  /**
   * Getter logoLoginLateral
   * @return {string}
   */
  public get logoLoginLateral(): string {
    return this._logoLoginLateral;
  }

  /**
   * Setter logoLoginLateral
   * @param {string} value
   */
  public set logoLoginLateral(value: string) {
    this._logoLoginLateral = value;
  }

  /**
   * Getter username
   */
  get username() {
    return this.loginForm.get("username");
  }

  /**
   * Getter key
   */
  get key() {
    return this.loginForm.get("key");
  }

  /**
   * Getter textTitle
   * @return {string}
   */
  public get textTitle(): string {
    return this._textTitle;
  }

  /**
   * Getter textSubTitle
   * @return {string}
   */
  public get textSubTitle(): string {
    return this._textSubTitle;
  }

  /**
   * Setter textTitle
   * @param {string} value
   */
  public set textTitle(value: string) {
    this._textTitle = value == "login.text.title" ? "" : value;
  }

  /**
   * Setter textSubTitle
   * @param {string} value
   */
  public set textSubTitle(value: string) {
    this._textSubTitle = value == "login.text.subtitle" ? "" : value;
  }

  ngOnInit() {
    this.setLogos();
    this.createLoginForm();
    this.authenticationService.logout();
  }
  setLogos() {
    this.logoLogin = environment.logos.logo_login;
    this.logoLoginLateral = environment.logos.logo_login_lateral;
    this.textTitle = this.translate.instant("login.text.title") ?? "";
    this.textSubTitle = this.translate.instant("login.text.subtitle") ?? "";
  }
  /**
   */
  private createLoginForm() {
    this.loginForm = this.fb.group({
      username: new FormControl("", [Validators.required]),
      key: new FormControl("", [Validators.required]),
    });
  }

  /**
   * Submit del formulario de login
   */
  onSubmit() {
    if (this.loginForm.valid) {
      const user = this.loginForm.value;
      if (user.username && user.key) {
        this.authenticationService.login(user).subscribe((res: any) => {
          this.processLoginResponse(res);
        });
      }
    } else {
      this.validationFormService.validateAllFormFields(this.loginForm);
    }
  }

  /**
   * Procesa la respuesta del login
   * @param  {any} res
   */
  protected processLoginResponse(res: any) {
    if (res) {
      this.router.navigateByUrl("/");
    }
  }
  /**
   * Resetea el formulario de login
   */
  reset() {
    this.loginForm.reset();
  }
}
