import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { User } from "src/app/customized/seguridad/user/model/user";
import { Token } from "src/app/jnum-core/model/token";
import { NavigationService } from "src/app/jnum-core/services/navigation.service";
import { environment } from "src/environments/environment";

const loginUrl = environment.serviceUrl + "/jwt/authenticate";

/**
 * Servicio de autenticación de usuario
 */
@Injectable()
export class AuthenticationService {
  private _isLoggedIn: boolean = false;
  private _currentUserSubject!: BehaviorSubject<boolean>;
  private _currentUser!: Observable<boolean>;
  private _token!: Token | null;

  constructor(
    protected http: HttpClient,
    protected navigationService: NavigationService
  ) {
    this.currentUserSubject = new BehaviorSubject<boolean>(false);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   * Realiza el login del usuario
   *
   * @param  {User} user
   * @returns Observable
   */
  login(user: User): Observable<any> {
    return this.http
      .post<any>(loginUrl, user, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }),
      })
      .pipe(
        tap((token: Token) => {
          this.processToken(token, user);
        })
      );
  }

  /**
   * Procesa el token del usuario.
   *
   * @param  {Token} token
   * @param  {User} user
   */
  protected processToken(token: Token, user: User) {
    this.token = token;
    this.isLoggedIn = true;
    user.key = "";
    sessionStorage.setItem(
      "currentUser",
      JSON.stringify({ user, token: token })
    );
    this.currentUserSubject.next(true);
  }
  /**
   * Logout del usuario.
   *
   * @returns void
   */
  logout(): void {
    this.token = null;
    this.isLoggedIn = false;
    this.navigationService.clearHistory();
    sessionStorage.removeItem("currentUser");
    sessionStorage.clear();
    this.currentUserSubject.next(false);
  }

  /**
   * Getter isLoggedIn
   * @return {boolean }
   */
  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  /**
   * Setter isLoggedIn
   * @param {boolean } value
   */
  public set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
  }

  /**
   * Getter currentUserSubject
   * @return {BehaviorSubject<boolean>}
   */
  public get currentUserSubject(): BehaviorSubject<boolean> {
    return this._currentUserSubject;
  }

  /**
   * Getter currentUser
   * @return {Observable<boolean>}
   */
  public get currentUser(): Observable<boolean> {
    return this._currentUser;
  }

  /**
   * Setter currentUserSubject
   * @param {BehaviorSubject<boolean>} value
   */
  public set currentUserSubject(value: BehaviorSubject<boolean>) {
    this._currentUserSubject = value;
  }

  /**
   * Setter currentUser
   * @param {Observable<boolean>} value
   */
  public set currentUser(value: Observable<boolean>) {
    this._currentUser = value;
  }

  /**
   * Getter token
   * @return {Token}
   */
  public get token(): Token | null {
    return this._token;
  }

  /**
   * Setter token
   * @param {Token} value
   */
  public set token(value: Token | null) {
    this._token = value;
  }
}
