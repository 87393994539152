import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import {
  NgbActiveModal,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { AngularDraggableModule } from "angular2-draggable";
import { NgxSpinnerModule } from "ngx-spinner";
import { MenuVerticalItemComponent } from "src/app/jnum-core/menu-item/menu-vertical-item.component";
import { NavbarVerticalComponent } from "src/app/jnum-core/navbar/navbar-vertical.component";
import { DocfileService } from "../customized/comun/docfile/service/docfile.service";
import { UserService } from "../customized/seguridad/user/service/user.service";
import {
  JnumDateAdapter,
  JnumDateParserFormatter,
} from "./adapters/datepicker-adapter";
import { HasPermissionDirective } from "./directives/has-permission.directive";
import { SortableHeaderDirective } from "./directives/sortable-header.directive";
import { UppercaseInputDirective } from "./directives/uppercase.directive";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { MenuItemComponent } from "./menu-item/menu-item.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { RegisterComponent } from "./register/register.component";
import { ShowErrorsComponent } from "./show-errors/show-errors.component";
import { TabsComponent } from "./tabs.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { PortalModule } from "../customized/portal/portal.module";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragDropModule,
    ScrollingModule,
    AngularDraggableModule,
    NgxSpinnerModule,
    AngularEditorModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PortalModule
],
  declarations: [
    ShowErrorsComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    NavbarComponent,
    NavbarVerticalComponent,
    MenuItemComponent,
    MenuVerticalItemComponent,
    HasPermissionDirective,
    UppercaseInputDirective,
    SortableHeaderDirective,
    TabsComponent,
  ],
  providers: [
    DocfileService,
    NgbActiveModal,
    { provide: NgbDateAdapter, useClass: JnumDateAdapter },
    { provide: NgbDateParserFormatter, useClass: JnumDateParserFormatter },
    UserService,
  ],
  exports: [
    ShowErrorsComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    NavbarComponent,
    NavbarVerticalComponent,
    MenuItemComponent,
    MenuVerticalItemComponent,
    DragDropModule,
    HasPermissionDirective,
    UppercaseInputDirective,
    SortableHeaderDirective,
    ScrollingModule,
    NgxSpinnerModule,
    TabsComponent,
    NgbModule,
    TranslateModule,
    AngularEditorModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
})
export class JnumCoreModule {}
