import { Component, Input } from "@angular/core";
import {
  AbstractControl,
  AbstractControlDirective,
  ValidationErrors,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

/**
 * Gestion de errores en el front
 */
@Component({
  selector: "app-show-errors",
  templateUrl: "./show-errors.component.html",
  styleUrls: ["./show-errors.component.scss"],
})
export class ShowErrorsComponent {
  @Input()
  public control!: AbstractControlDirective | AbstractControl | null;

  private readonly errorMessages: any = {
    required: (params: any) => this.translate.instant("validations.required"),
    minlength: (params: any) =>
      this.translate.instant("validations.minlength", {
        minlength: params.requiredLength,
      }),
    maxlength: (params: any) =>
      this.translate.instant("validations.maxlength", {
        maxlength: params.requiredLength,
      }),
    min: (params: any) =>
      this.translate.instant("validations.min", { min: params.min }),
    max: (params: any) =>
      this.translate.instant("validations.max", { max: params.max }),
    pattern: (params: any) => this.translate.instant("validations.pattern"),
    email: (params: any) => this.translate.instant("validations.email"),
    date: (params: any) => this.translate.instant("validations.date"),
    dateMenorActual: (params: any) =>
      "La fecha introducida debe ser menor que la actual.",
    matchpassword: (params: any) =>
      this.translate.instant("validations.matchpassword"),
    matcholdpassword: (params: any) =>
      this.translate.instant("validations.matcholdpassword"),
    number: (params: any) => this.translate.instant("validations.number"),
    double: (params: any) => this.translate.instant("validations.double"),
    ngbDate: (params: any) => this.translate.instant("validations.pattern"),
    datelessthan: (params: any) =>
      this.translate.instant("validations.datelessthan", {
        dateini: params.dateini,
        datefin: params.datefin,
      }),
    datelessthaneq: (params: any) =>
      this.translate.instant("validations.datelessthaneq", {
        dateini: params.dateini,
        datefin: params.datefin,
      }),
    lessthan: (params: any) =>
      this.translate.instant("validations.lessthan", {
        valueini: params.valueini,
        valuefin: params.valuefin,
      }),
    lessthaneq: (params: any) =>
      this.translate.instant("validations.lessthaneq", {
        valueini: params.valueini,
        valuefin: params.valuefin,
      }),
    greaterThanValue: (params: any) =>
      this.translate.instant("validations.greaterThanValue", {
        initialText: params.initialText,
        finalText: params.finalText,
      }),
    greaterOrEqualValue: (params: any) =>
      this.translate.instant("validations.greaterOrEqualValue", {
        initialText: params.initialText,
        finalText: params.finalText,
      }),
    lessThanValue: (params: any) =>
      this.translate.instant("validations.lessThanValue", {
        initialText: params.initialText,
        finalText: params.finalText,
      }),
    lessOrEqualValue: (params: any) =>
      this.translate.instant("validations.lessOrEqualValue", {
        initialText: params.initialText,
        finalText: params.finalText,
      }),
    notzero: (params: any) => this.translate.instant("validations.notzero"),
    nif: (params: any) => this.translate.instant("validations.nif"),
    positive: (params: any) => this.translate.instant("validations.positive"),
    incorrect: (params: any) => this.translate.instant("validations.incorrect"),
    custom: (params: any) => params,
    mask: (params: any) => "",
    url: (params: any) => this.translate.instant("validations.url"),
    validacionCif: (params: any) => this.translate.instant("validations.cif"),
  };

  constructor(private translate: TranslateService) {
    const language = sessionStorage.getItem("language")
      ? sessionStorage.getItem("language")
      : translate.getDefaultLang();
    translate.use(language ?? environment.defaultLocale);
  }

  /**
   * Comprueba si deberia mostrar el error.
   *
   * @returns boolean
   */
  shouldShowErrors(): boolean {
    const showerrors =
      this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched);
    return showerrors ?? false;
  }

  /**
   * Devuelve la lista de errores.
   *
   * @returns string
   */
  listOfErrors(): string[] {
    const errors: ValidationErrors = this.control?.errors ?? [];
    if (errors) {
      return Object.keys(errors).map((field) =>
        this.getMessage(field, errors[field], this.control)
      );
    }
    return [];
  }

  /**
   * Getter error.
   *
   * @returns string
   */
  getError(): string {
    const contolErrors: ValidationErrors = this.control?.errors ?? [];
    if (contolErrors) {
      const errors = Object.keys(contolErrors).map((field) =>
        this.getMessage(field, contolErrors[field], this.control)
      );
      return errors[0];
    }
    return "";
  }

  /**
   * Getter message.
   *
   * @param  {string} type
   * @param  {any} params
   * @param  {any} control
   */
  getMessage(type: string, params: any, control: any): string {
    let fname = this.getControlName(control);
    if (fname) {
      fname = fname.replace("_", " ").replace(" id", "").toLowerCase();
      fname = fname.replace(/\b\w/g, (l) => l.toUpperCase());
      const msg = this.errorMessages[type](params);
      return msg.replace("##FIELD##", fname);
    }
    return "";
  }

  /**
   * Getter controlName.
   *
   * @param  {AbstractControl} c
   * @returns string
   */
  getControlName(c: AbstractControl): string | null {
    if (c.parent) {
      const formGroup: any = c.parent.controls;
      return (
        Object.keys(formGroup).find((name) => c === formGroup[name]) || null
      );
    }
    return null;
  }
}
